import React from "react";
const defaultCover = require("../../../assets/img/profiles/photo_empty_image.png");

const ProfileHeader = ({
  name = "Profile Name",
  image = defaultCover,
  description = "description",
}) => {
  return (
    <section
      className={
        "h-[30vh] md:h-[30vh] flex flex-col items-center justify-center bg-[#071020]"
      }
    >
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row md:space-x-5 items-center justify-center">
          <figure className="w-[10rem] h-[10rem] bg-gray-100 flex flex-col rounded-full shadow-md overflow-auto">
            <img
              src={image}
              alt={name}
              className="w-full h-full object-cover"
            />
          </figure>
          <div className="md:flex-1 space-y-2">
            <h1 className="font-bold text-5xl text-white">{name}</h1>
            <h1 className="font-medium text-lg text-white">{description}</h1>
            <button className="px-10 py-3 space-x-2 flex items-center bg-white rounded-full">
              <span className="font-semibold">S'abonner</span>
              <svg
                class="svg-icon svg-icon-play"
                focusable="false"
                height="1em"
                role="img"
                width="1em"
                viewBox="0 0 12 12"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                  d="M2.5.5v11l9-5.5z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

ProfileHeader.propTypes = {};

export default ProfileHeader;
