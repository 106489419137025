import React from "react";

export default function RadioGroup({
  label,
  checked,
  defaultChecked = false,
  nameOrId = "input_0",
  options = [],
}) {
  return (
    <div class="flex flex-col mb-4">
      <label className="block text-sm font-semibold text-gray-700">
        {label}
      </label>
      <div className="space-y-2 pt-2">
        {options.map((item, x) => (
          <div>
            <input
              type="radio"
              id={`${nameOrId}_${x}`}
              name={nameOrId}
              value={item.value}
              defaultChecked={item.checked}
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 mr-2"
              onChange={(ev) => {
                let checked = ev.target.checked;
                if (item.onChecked) item.onChecked(checked);
              }}
            />
            <label
              for="default-checkbox"
              class="text-sm font-medium text-gray-900"
            >
              {item.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}
