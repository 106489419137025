import { connect } from "react-redux";
import { getPodcast } from "../../actions/creators/library";
import { getChapters } from "../../actions/chapter";
import { getPodcastCategories as getCategories } from "../../actions/metadata";
import { getPodcastEpisodes } from "../../actions/creators/podcast";
import {
  getChaptersByPodcast,
  getEpisodesByPodcast,
  getPodcastById,
} from "../../selectors/PodcastSelectors";
import component from "../../content/learning/course";

const mapStateToProps = (state, ownProps) => ({
  loading: state.metadata.loadingCreate,
  createPodcastId: state.metadata.createPodcastId,
  podcast: getPodcastById(state, ownProps.match.params.slug),
  episodes: getEpisodesByPodcast(state, ownProps.match.params.slug),
  chapters: getChaptersByPodcast(state, ownProps.match.params.slug),
});

const mapDispatchToProps = {
  getCategories,
  getPodcast,
  getPodcastEpisodes,
  getChapters,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
