import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Error404 from "../content/common/page404";

import PageSignin from '../containers/session/signin'
import PageSignup from '../containers/session/signup'

import PodcastCategory from '../containers/podcast/category'
import PodcastProfile from '../containers/podcast/profile'
import PodcastSingle from '../containers/podcast/single'

import AccountPage from '../containers/account/index'
import AccountProfile from '../containers/account/profile'
import AccountPassword from '../containers/account/password'
import AccountProfiles from '../containers/profile/index'
import AccountProfileCreate from '../containers/profile/create'
import AccountProfileEdit from '../containers/profile/edit'

import AccountLibrary from '../containers/podcast/index'
import AccountPodcastPage from '../containers/podcast/single'
import AccountPodcastCreate from '../containers/podcast/create'
import AccountPodcastEdit from '../containers/profile/podcastedit'

import AccountPreachList from '../containers/creators/preachs'
import AccountPreachPage from '../containers/profile/preach'
import AccountPreachCreate from '../containers/profile/preachcreate'
import AccountPreachEdit from '../containers/profile/preachedit'

import AccountLearningList from '../containers/creators/learning'
import AccountLearningPage from '../containers/creators/course'
import AccountLearningCreate from '../containers/creators/coursecreate'
import AccountLearningEdit from '../containers/creators/courseedit'

import AccountMusicList from '../containers/creators/music'
import AccountMusicPage from '../containers/creators/release'
import AccountMusicCreate from '../containers/creators/releasecreate'
import AccountMusicEdit from '../containers/musicrelease/edit'
import PageNotifications from "../content/account/notifications";
import PageRevenue from "../content/revenue";

class BaseRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Switch>
        <Route exact path="/" component={PageSignin} />

        <Route exact path="/sign-in" component={PageSignin} />
        <Route exact path="/sign-up" component={PageSignup} />

        <Route exact path="/home" component={AccountPage} />
        <Route exact path="/profile" component={AccountProfile} />
        <Route exact path="/password" component={AccountPassword} />
        <Route exact path="/library" component={AccountLibrary} />
        <Route exact path="/notifications" component={PageNotifications} />
        <Route exact path="/revenue" component={PageRevenue} />
        <Route exact path="/profiles" component={AccountProfiles} />
        <Route exact path="/profiles/create" component={AccountProfileCreate} />
        <Route exact path="/profiles/:id" component={AccountProfileEdit} />

        <Route exact path="/podcasts/create" component={AccountPodcastCreate} />
        <Route exact path="/podcasts/:slug/edit" component={AccountPodcastEdit} />
        <Route exact path="/podcasts/:slug" component={AccountPodcastPage} />
        <Route exact path="/podcasts" component={AccountLibrary} />

        <Route exact path="/preachs/create" component={AccountPreachCreate} />
        <Route exact path="/preachs/:slug/edit" component={AccountPreachEdit} />
        <Route exact path="/preachs/:slug" component={AccountPreachPage} />
        <Route exact path="/preachs" component={AccountPreachList} />

        <Route exact path="/learning/create" component={AccountLearningCreate} />
        <Route exact path="/learning/:slug/edit" component={AccountLearningEdit} />
        <Route exact path="/learning/:slug" component={AccountLearningPage} />
        <Route exact path="/learning" component={AccountLearningList} />

        <Route exact path="/music/create" component={AccountMusicCreate} />
        <Route exact path="/music/:slug/edit" component={AccountMusicEdit} />
        <Route exact path="/music/:slug" component={AccountMusicPage} />
        <Route exact path="/music" component={AccountMusicList} />

        <Route exact path="/podcasts/profile/:slug" component={PodcastProfile} />
        <Route exact path="/podcasts/category/:slug" component={PodcastCategory} />
        <Route exact path="/podcasts/podcast/:slug" component={PodcastSingle} />
        <Route exact path="/podcasts/podcast/:slug/episode/:id" component={PodcastSingle} />

        <Route exact path="/library/:slug" component={AccountLibrary} />
        <Route exact path="/settings/profile/:slug" component={AccountProfileEdit} />
        <Route exact path="/:slug" component={AccountLibrary} />


        <Route component={Error404} />
      </Switch>
    );
  }
}

export default BaseRouter;
