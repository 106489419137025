const categories = (state) => state.metadata.categories;
const podcastsById = (state) => state.metadata.podcastsById || {};
const episodesByPodcast = (state) => state.entities.episodesByPodcast || {};
const chaptersByPodcast = (state) => state.entities.chaptersByPodcast || {};

export const getCategoriesByKind = (state, kind) => {
  let collection = categories(state);

  return collection[kind] || [];
};

export const getPodcastById = (state, kind) => {
  let collection = podcastsById(state);

  return collection[kind] || null;
};

export const getEpisodesByPodcast = (state, kind) => {
  let collection = episodesByPodcast(state);

  return collection[kind] || [];
};

export const getChaptersByPodcast = (state, refId) => {
  let collection = chaptersByPodcast(state);

  return collection[refId] || [];
};
