import { connect } from "react-redux";
import { editPassword } from "../../actions/profile";
import component from "../../content/account/password";

const mapStateToProps = (state, ownProps) => ({
  loading: state.profile.loading,
});

const mapDispatchToProps = { editPassword };

export default connect(mapStateToProps, mapDispatchToProps)(component);
