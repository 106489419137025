import { Button } from "@tremor/react";
import React, { Component } from "react";

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { title = "Modal Title", buttons = [], children } = this.props;
    return (
      <div
        className="fixed z-10 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          />
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            ​
          </span>
          <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg md:max-w-xl sm:w-full">
            <div className="bg-gray-50 px-6 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:justify-between">
                <div className="mt-3 text-left sm:mt-0 md:text-center">
                  <h3
                    className="text-lg leading-6 font-bold text-gray-900"
                    id="modal-title"
                  >
                    {title}
                  </h3>
                </div>
              </div>
            </div>
            {children}
            <div className="bg-gray-50 px-4 py-3 sm:px-6 flex space-x-3 justify-end">
              {buttons.map((item, index) => this.renderButton(item, index))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderButton(item, index) {
    const { type = "outline" } = item;
    if (type === "primary")
      return (
        <Button key={index} variant="primary" disabled={item.disabled} loading={item.loading} onClick={item.onClick}>
          {item.content}
        </Button>
      );

    return (
      <Button key={index} variant="secondary" disabled={item.disabled} loading={item.loading} onClick={item.onClick}>
        {item.content}
      </Button>
    );
  }
}

export default Modal;
