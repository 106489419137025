/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { Component } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import Plyr from "plyr";
import {
  MicrophoneIcon,
  ArrowUpTrayIcon,
  XMarkIcon,
  ArchiveBoxIcon,
} from "@heroicons/react/24/outline";

import { classNames } from "../../helpers/classNames";
import { editEpisode } from "../../actions/creators/podcast";

import Button from "../../components/form/button";
import ProgressBar from "../../components/progress/indeterminate";
import TextField from "../../components/form/input";
import TextArea from "../../components/form/textarea";
import RadioGroup from "../../components/form/radiogroup";
import AudioUploader from "../../components/upload/audio";
import AudioRecorder from "../../components/recorder/audio";
import Select from "../../components/form/select";

class PageEdit extends Component {
  constructor(props) {
    super(props);
    const { episode } = props;
    this.state = {
      fileUploading: false,
      textUploading: "Aperçu de votre fichier",
      audioFile: null,
      audioUrl: episode.url,
      audioType: null,
      audioFileName: null,
      audioDuration: 0,
      showPicker: false,
      activeTab: "UPLOAD_AUDIO",

      epTitle: episode.title,
      epDescription: episode.description,
      epSeasonNumber: episode.season_number,
      epChapterId: episode.chapter_id,
      epNumber: episode.number,
      epExplicit: episode.is_content_explicit,
      epType: episode.type || "episode",
      epPublished: episode.is_published || false,
    };
  }

  componentDidMount() {
    setTimeout(() => this.initPlayer(), 400);
  }

  initPlayer() {
    let playerElement = document.getElementById("player-preview");
    if (playerElement) {
      this.player = new Plyr(playerElement);
    }
  }

  replacePlayerSource() {
    const { audioUrl, audioFileName, audioType } = this.state;
    if (!this.player) return;

    try {
      this.player.autoplay = true;
      this.player.source = {
        type: "audio",
        title: audioFileName,
        sources: [{ src: audioUrl, type: audioType || "audio/mp3" }],
      };
    } catch (error) {}
  }

  onRequestClose() {
    const { onRequestClose } = this.props;
    if (onRequestClose) onRequestClose();
  }

  onRequestSubmit() {
    const {
      epTitle,
      epDescription,
      epExplicit,
      epType,
      epNumber,
      epSeasonNumber,
      epChapterId,
      epPublished,
      audioDuration,
      audioUrl,
      audioFile,
      audioType,
      audioFileName,
    } = this.state;

    if (epTitle === "" || epDescription === "") {
      toast("Veuillez remplir tous les champs réquis", {
        position: "bottom-center",
        type: "error",
      });
      return;
    }

    let bodyRequest = {
      audio: null,
      type: epType,
      title: epTitle,
      description: epDescription,
      is_content_explicit: epExplicit,
      chapter_id: epChapterId,
      number: epNumber !== "" ? epNumber : null,
      season_number: epSeasonNumber !== "" ? epSeasonNumber : null,
      is_published: epPublished,
    };

    if (audioFile) {
      bodyRequest.audio = {
        url: audioUrl,
        type: audioType,
        duration: audioDuration,
        filename: audioFileName,
      };
    }

    const { editEpisode, episode } = this.props;
    if (editEpisode) editEpisode(episode.id, { ...bodyRequest });
  }

  render() {
    const {
      episode,
      chapters = [],
      pageTitle = "Modifier votre épisode",
      aboutText = "Informations sur l'épisode",
    } = this.props;
    const {
      activeTab,
      fileUploading,
      textUploading,
      audioUrl,
      epTitle,
      epDescription,
      epNumber,
      epSeasonNumber,
      epChapterId,
      epExplicit,
      epPublished,
      epType,
      showPicker,
    } = this.state;
    return (
      <div>
        <div className="flex space-x-2 justify-between">
          <h1 className="text-4xl font-bold">{pageTitle}</h1>
          <a href="javascript:void(0);" onClick={() => this.onRequestClose()}>
            <XMarkIcon className="w-10 h-10 text-gray-400" />
          </a>
        </div>
        <hr className="my-5" />
        <div className="py-5 flex space-x-10">
          <div className="w-full px-5 relative">
            <div className="flex space-x-5">
              <div className="w-[40%]">
                <div className="border-[0.5px] px-10 py-5 rounded">
                  {fileUploading && <ProgressBar />}
                  <h1>{textUploading}</h1>
                  <hr className="my-5" />
                  {epTitle && (
                    <h1 className="font-bold text-xl mb-3">{epTitle}</h1>
                  )}
                  <audio
                    controls={true}
                    id={"player-preview"}
                    style={{ width: "100%" }}
                  >
                    <source src={audioUrl} type="audio/mp3" />
                  </audio>
                </div>
                <div className="flex justify-end pt-5">
                  <div>
                    <Button
                      disabled={fileUploading}
                      iconLeft={null}
                      iconRight={null}
                      content="Remplacer l'audio"
                      onClick={() => this.setState({ showPicker: true })}
                    />
                  </div>
                </div>

                {showPicker && (
                  <div className="flex space-x-4 bg-white py-10">
                    <div className="space-y-4 flex flex-col">
                      {[
                        {
                          key: "UPLOAD_AUDIO",
                          title: "Uploader un audio",
                          leftIcon: <ArrowUpTrayIcon className="w-5 h-5" />,
                          onClick: () =>
                            this.setState({ activeTab: "UPLOAD_AUDIO" }),
                        },
                        {
                          key: "RECORD_AUDIO",
                          title: "Enregistrer un audio",
                          leftIcon: <MicrophoneIcon className="w-5 h-5" />,
                          onClick: () =>
                            this.setState({ activeTab: "RECORD_AUDIO" }),
                        },
                        {
                          key: "LIBRARY_AUDIO",
                          title: "Choisir depuis la bibliothèque",
                          leftIcon: <ArchiveBoxIcon className="w-5 h-5" />,
                          onClick: () =>
                            this.setState({ activeTab: "LIBRARY_AUDIO" }),
                        },
                      ].map((item, x) => (
                        <a href="javascript:void(0);" onClick={item.onClick}>
                          <div
                            className={classNames(
                              "w-[19rem] p-6 py-4 border-[1px] border-gray-300 rounded flex items-center space-x-3",
                              activeTab === item.key && " bg-slate-200"
                            )}
                          >
                            {item.leftIcon}
                            <h1 className="font-medium text-sm">
                              {item.title}
                            </h1>
                          </div>
                        </a>
                      ))}
                    </div>
                    <div className="w-full border-l pl-5">
                      {activeTab === "UPLOAD_AUDIO" && (
                        <AudioUploader
                          onGotUrl={(audioUrl) =>
                            this.setState({ audioUrl }, () => {
                              this.replacePlayerSource();
                            })
                          }
                          onGotMetadata={(info) => {
                            this.setState({
                              audioType: info.type,
                              audioFile: info.file,
                              audioFileName: info.name,
                              audioDuration: info.duration,
                            });
                          }}
                        />
                      )}
                      {activeTab === "RECORD_AUDIO" && <AudioRecorder />}
                    </div>
                  </div>
                )}
              </div>
              <div className="w-1/2">
                <h1 className="font-semibold text-2xl">{aboutText}</h1>
                <p>
                  Ajoutez des informations et définissez la date de publication.
                </p>
                <div className="my-5 space-y-3">
                  {Array.isArray(chapters) && (
                    <>
                      {Array.from(chapters).length > 0 && (
                        <Select
                          label="Sélectionner un chapitre"
                          placeholder="Sélectionner dans la liste"
                          value={epChapterId}
                          options={chapters.map((item) => ({
                            value: item.id,
                            label: item.title,
                          }))}
                          onChange={(el) => this.setState({ epChapterId: el })}
                        />
                      )}
                    </>
                  )}
                  <TextField
                    nameOrId="episode-name"
                    label={"Titre de l'épisode *"}
                    placeholder="Titre"
                    value={epTitle}
                    defaultValue={epTitle}
                    onChange={(epTitle) => this.setState({ epTitle })}
                  />
                  <TextArea
                    nameOrId="episode-description"
                    label={"Description de l'épisode *"}
                    placeholder="Entrez une brève description"
                    value={epDescription}
                    defaultValue={epDescription}
                    onChange={(epDescription) =>
                      this.setState({ epDescription })
                    }
                  />
                  <RadioGroup
                    label={"Votre épisode comporte-t-il du contenu explicite ?"}
                    nameOrId="explicit-content"
                    options={[
                      {
                        label: "Oui",
                        value: "include_explicit_content",
                        checked: episode.is_content_explicit === true,
                        onChecked: () => this.setState({ epExplicit: true }),
                      },
                      {
                        label: "Non",
                        value: "no_explicit_content",
                        checked: episode.is_content_explicit === false,
                        onChecked: () => this.setState({ epExplicit: false }),
                      },
                    ]}
                  />
                  <RadioGroup
                    label={"Type d'épisode"}
                    nameOrId="episode-type"
                    options={[
                      {
                        label: "Complet",
                        value: "episode",
                        checked: episode.type === "episode",
                        onChecked: () => this.setState({ epType: "episode" }),
                      },
                      {
                        label: "Bande-annonce",
                        value: "trailer",
                        checked: episode.type === "trailer",
                        onChecked: () => this.setState({ epType: "trailer" }),
                      },
                      {
                        label: "Bonus",
                        value: "bonus",
                        checked: episode.type === "bonus",
                        onChecked: () => this.setState({ epType: "bonus" }),
                      },
                    ]}
                  />
                  <div className="flex space-x-5">
                    <TextField
                      type="number"
                      nameOrId="episode-season"
                      label="Numéro de la saison"
                      placeholder={"ex : 1"}
                      value={epSeasonNumber}
                      defaultValue={epSeasonNumber}
                      onChange={(epSeasonNumber) =>
                        this.setState({ epSeasonNumber })
                      }
                    />
                    <TextField
                      type="number"
                      nameOrId="episode-number"
                      label="Numéro de l'épisode"
                      placeholder={"ex : 5"}
                      value={epNumber}
                      defaultValue={epNumber}
                      onChange={(epNumber) => this.setState({ epNumber })}
                    />
                  </div>

                  <RadioGroup
                    label={"Disponibilité dans Yoka"}
                    nameOrId="publish-state"
                    options={[
                      {
                        label: "Publié",
                        value: "is_published",
                        checked: episode.is_published === true,
                        onChecked: () => this.setState({ epPublished: true }),
                      },
                      {
                        label: "Brouillon",
                        value: "is_not_published",
                        checked: episode.is_published === false,
                        onChecked: () => this.setState({ epPublished: false }),
                      },
                    ]}
                  />
                </div>
              </div>
            </div>

            <hr />
            <div className="flex justify-between pt-2">
              <div>
                <Button
                  disabled={fileUploading}
                  iconLeft={null}
                  iconRight={null}
                  content="Quitter"
                  onClick={() => this.onRequestClose()}
                />
              </div>
              <div>
                <Button
                  buttonStyle={"primary"}
                  disabled={fileUploading}
                  iconLeft={null}
                  iconRight={null}
                  content="Enregistrer les modifications"
                  onClick={() => this.onRequestSubmit()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loadingEdit: state.entities.loadingEdit,
});

const mapDispatchToProps = { editEpisode };

export default connect(mapStateToProps, mapDispatchToProps)(PageEdit);
