import { connect } from "react-redux";
import { getProfiles } from "../../actions/profile";
import { getPodcasts } from "../../actions/creators/library";
import component from "../../content/library/preach";

const mapStateToProps = (state, ownProps) => ({
  loading: state.profile.loading,
  loadingPodcasts: state.profile.loadingPodcasts || false,
  profiles: state.profile.profiles || [],
  podcasts: state.metadata.podcasts || [],
});

const mapDispatchToProps = {
  getProfiles,
  getPodcasts,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
