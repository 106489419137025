import { ArrowRightIcon } from "@heroicons/react/24/outline";
import React from "react";
import { classNames } from "../../helpers/classNames";

const ICON_DEFAULT = <ArrowRightIcon className="w-6 h-6" />;

export default function Button({
  disabled = false,
  content = "Button content",
  iconLeft = ICON_DEFAULT,
  iconRight = ICON_DEFAULT,
  buttonStyle = "outline",
  onClick = null,
}) {
  return (
    <button
      disabled={disabled}
      className={classNames(
        "w-full block px-6 py-3 rounded-full",
        buttonStyle === "outline" &&
          "border border-gray-200 hover:border-gray-500 text-gray-700",
        buttonStyle === "primary" &&
          "text-primary-500 border border-primary-500 bg-white hover:bg-primary-500 hover:text-white"
      )}
      onClick={onClick}
    >
      <div className="flex items-center justify-center space-x-5 ">
        {iconLeft}
        <span className="text-sm font-bold">{content}</span>
        {iconRight}
      </div>
    </button>
  );
}
