import React, { Component } from "react";
import {
  Card,
  TabList,
  Tab,
  ProgressBar,
  Text,
  Flex,
  Grid,
  Button,
  Metric,
  BadgeDelta,
  TabGroup,
  Select,
  SelectItem,
} from "@tremor/react";
import {
  BellIcon,
  ArrowLongRightIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/outline";
import Header from "../../components/header/home";
import PageLayout from "../../components/layout/page";
import EmptyView from "../../components/list/empty";
import useProfiles from "../../hooks/useProfiles";

const categories = [
  {
    title: "Ventes",
    metric: "$ 0,00",
  },
  {
    title: "Profit",
    metric: "$ 0,00",
  },
  {
    title: "Retirés",
    metric: "$ 0,00",
  },
];

const products = [
  {
    title: "Product A",
    value: 38,
    metric: "$ 0,00",
    location: "A",
  },
  {
    title: "Product B",
    value: 34,
    metric: "$ 0,00",
    location: "A",
  },
  {
    title: "Product C",
    value: 28,
    metric: "$ 0,00",
    location: "A",
  },
  {
    title: "Product Z",
    value: 82,
    metric: "$ 0,00",
    location: "B",
  },
  {
    title: "Product E",
    value: 10,
    metric: "$ 0,00",
    location: "B",
  },
  {
    title: "Product N",
    value: 8,
    metric: "$ 0,00",
    location: "B",
  },
];

const Page = () => {
  const [selectedIndex] = React.useState(0);
  const [selectedLocation] = React.useState("A");
  const { profiles } = useProfiles();

  const onSelectItemHandler = (value) => {};

  return (
    <div className="h-full bg-white">
      <Header />
      <PageLayout>
        <div className="bg-white py-10">
          <h1 className="text-4xl font-bold">Revenu</h1>
          <hr className="my-5" />

          <div className="flex">
            <div className="space-y-3 md:w-1/3 lg:w-3/12">
              <Select
                icon={Squares2X2Icon}
                placeholder="Sélectionner un profil"
                onValueChange={(value) => onSelectItemHandler(value)}
              >
                {profiles.map((item) => (
                  <SelectItem value={item.id}>{item.name}</SelectItem>
                ))}
              </Select>
            </div>
          </div>
          <hr className="my-5" />

          <div className="flex space-x-5">
            <div className="w-5/12">
              <Card className="">
                <Flex alignItems="start">
                  <Text>Total Ventes</Text>
                  <BadgeDelta deltaType="moderateIncrease">0.0%</BadgeDelta>
                </Flex>
                <Flex
                  justifyContent="start"
                  alignItems="baseline"
                  className="space-x-3 truncate"
                >
                  <Metric>$ 0,00</Metric>
                  <Text>from $ 0,00</Text>
                </Flex>
                <TabGroup
                  index={selectedIndex}
                  onIndexChange={(index) => {}}
                  className="mt-6"
                >
                  <TabList>
                    <Tab>Podcast</Tab>
                    <Tab>Education</Tab>
                    <Tab>Prédication</Tab>
                    <Tab>Musique</Tab>
                    <Tab>Autres</Tab>
                  </TabList>
                </TabGroup>
                {products
                  .filter((item) => item.location === selectedLocation)
                  .map((item) => (
                    <div key={item.title} className="space-y-2 mt-4">
                      <Flex>
                        <Text>{item.title}</Text>
                        <Text>{`${item.value}% (${item.metric})`}</Text>
                      </Flex>
                      <ProgressBar value={item.value} />
                    </div>
                  ))}
                <Flex className="mt-6 pt-4 border-t">
                  <Button
                    size="xs"
                    variant="light"
                    icon={ArrowLongRightIcon}
                    iconPosition="right"
                  >
                    View more
                  </Button>
                </Flex>
              </Card>
            </div>
            <div className="w-full">
              <Grid numItemsSm={2} numItemsLg={3} className="gap-6">
                {categories.map((item) => (
                  <Card key={item.title}>
                    <Text>{item.title}</Text>
                    <Metric>{item.metric}</Metric>
                  </Card>
                ))}
              </Grid>
            </div>
          </div>
        </div>
      </PageLayout>
    </div>
  );
};

export default Page;
