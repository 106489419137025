import { connect } from "react-redux";
import { editTrack } from "../../actions/creators/release";
import component from "../../content/releasetrack/edit";

const mapStateToProps = (state, ownProps) => ({
  loading: state.entities.loadingEdit,
});

const mapDispatchToProps = { editTrack };

export default connect(mapStateToProps, mapDispatchToProps)(component);
