import { useCallback, useMemo } from "react";
import { usePodcastStore } from "./store";
import { podcastsList } from "../../api/podcastService";
import { getCookie } from "../../helpers/cookies";

export const usePodcasts = (profileId) => {
  const { podcasts: data, loading, setLoading, addItems } = usePodcastStore();
  const podcasts = Object.entries(data)
    .filter((p) => p[1].profile_id === profileId)
    .map((e) => e[1]);

  const fetchItems = useCallback(
    (params = {}) => {
      console.log("fetchItems [profileId]", profileId);

      const access_token = getCookie("access_token");
      setLoading(true);
      podcastsList({ ...params, profile_id: profileId, access_token })
        .then((result) => {
          const response = result.data;

          const { podcasts: _data } = response;
          if (_data) {
            addItems(_data);
          } else {
            throw new Error("Error occured");
          }
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    },
    [addItems, setLoading, profileId]
  );

  return useMemo(
    () => ({ podcasts, loading, fetchItems }),
    [fetchItems, loading, podcasts]
  );
};
