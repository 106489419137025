import { TextInput } from "@tremor/react";
import React from "react";

export default function TextField({
  prefix,
  label,
  suffix,
  defaultValue,
  type = "text",
  nameOrId = "input_0",
  placeholder = "placeholder",
  onChange,
  ...rest
}) {
  return (
    <div>
      {label && (
        <label
          htmlFor={nameOrId}
          className="block text-sm font-semibold text-gray-700"
        >
          {label}
        </label>
      )}
      <div className="mt-1 relative shadow-sm">
        <TextInput
          {...rest}
          type={type}
          title={placeholder}
          placeholder={placeholder}
          onChange={(ev) => {
            let value = ev.target.value;
            if (onChange) onChange(value);
          }}
        />
      </div>
    </div>
  );
}
