import React, { Component } from "react";
import Header from "../../components/header/home";
import PageLayout from "../../components/layout/page";
import TextField from "../../components/form/input";
import Button from "../../components/form/button";
import ProgressBar from "../../components/progress/indeterminate";
import { getCookie } from "../../helpers/cookies";
import { toast } from "react-toastify";
import { Card, TextInput } from "@tremor/react";
import { DocumentIcon, LockClosedIcon } from "@heroicons/react/24/outline";

class PagePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profileId: null,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    };
  }

  componentDidMount() {
    document.title = "Mot de passe - Yoka.cd";
    setTimeout(() => this.fetchProfile(), 500);
  }

  componentDidUpdate(prevProps, prevState) {
    const { profileId, profileName, emailAddress, genderSex, birthDate } =
      this.props;

    if (profileId !== prevState.profileId) {
      this.setState({
        profileName,
        emailAddress,
        genderSex,
        birthDate,
        profileId,
      });
    }
  }

  fetchProfile() {
    let profileId = getCookie("profile_id");

    const { getProfile } = this.props;
    if (getProfile) getProfile(profileId);
  }

  onRequestSubmit() {
    const { currentPassword, newPassword, confirmPassword } = this.state;
    if (
      currentPassword === "" ||
      newPassword === "" ||
      confirmPassword === ""
    ) {
      toast("Veuillez compléter tous les champs", {
        type: "warning",
        position: "bottom-center",
      });
      return;
    }

    if (newPassword !== confirmPassword) {
      toast("Les nouveaux mot de passe ne correspondent pas !", {
        type: "warning",
        position: "bottom-center",
      });
      return;
    }

    let bodyParams = {
      password_current: currentPassword,
      password_new: confirmPassword,
    };

    const { editPassword } = this.props;
    if (editPassword) editPassword(bodyParams);
  }

  render() {
    const { loading } = this.props;

    return (
      <div className="h-full bg-white">
        <Header />
        <PageLayout>
          <div className="bg-white py-10">
            <h1 className="text-4xl font-bold">Modifier le mot de passe</h1>
            <hr className="my-5" />
            {loading && <ProgressBar />}
            <div className="flex">
              <Card>
                <div className="space-y-3 md:w-1/3">
                  <div>
                    <span className="text-sm text-gray-500">
                      Mot de passe actuel *
                    </span>
                    <TextInput
                      icon={LockClosedIcon}
                      placeholder="Mot de passe actuel"
                      onChange={(evt) => {
                        let currentPassword = evt.target.value;
                        this.setState({ currentPassword });
                      }}
                    />
                  </div>

                  <div>
                    <span className="text-sm text-gray-500">
                      Nouveau mot de passe *
                    </span>
                    <TextInput
                      icon={LockClosedIcon}
                      placeholder="Nouveau mot de passe"
                      onChange={(evt) => {
                        let newPassword = evt.target.value;
                        this.setState({ newPassword });
                      }}
                    />
                  </div>

                  <div>
                    <span className="text-sm text-gray-500">
                      Titre de l'épisode *
                    </span>
                    <TextInput
                      icon={LockClosedIcon}
                      placeholder="Confirmer nouveau mot de passe"
                      onChange={(evt) => {
                        let confirmPassword = evt.target.value;
                        this.setState({ confirmPassword });
                      }}
                    />
                  </div>
                </div>

                <hr className="my-5" />
                <div className="flex space-x-3">
                  <div>
                    <Button
                      disabled={loading}
                      iconLeft={null}
                      iconRight={null}
                      content="Annuler"
                      onClick={() => {
                        this.props.history.push("/home");
                      }}
                    />
                  </div>
                  <div>
                    <Button
                      iconLeft={null}
                      iconRight={null}
                      disabled={loading}
                      content="Enregistrer le profil"
                      buttonStyle={"primary"}
                      onClick={() => this.onRequestSubmit()}
                    />
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default PagePassword;
