import axios from "axios";
import { API_BASE_HOST } from "../constants/api";
import { removeCookie } from "../helpers/cookies";

const ApiContext = axios.create({
  baseURL: `${API_BASE_HOST}/v1`,
  withCredentials: false,
  timeout: 30000,
  headers: { "Content-Type": "text/plain" },
});

ApiContext.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location.replace("/sign-in?logout=auto");
      removeCookie("access_token");
      removeCookie("account_id");
    }
    return Promise.reject(error.response);
  }
);

/**
 * categories
 */

export function releasesList(params = {}) {
  return ApiContext.get(`releases`, { params });
}

/**
 * categories
 */

export function releaseCreate(body, params = {}) {
  return ApiContext.post(`releases`, body, { params });
}

/**
 * Tracks
 */

export function releaseTrackCreate(body, params = {}) {
  return ApiContext.post(`creators/releasetracks`, body, { params });
}

export function releaseTrackEdit(refId, body, params = {}) {
  return ApiContext.post(`creators/releasetracks/edit/${refId}`, body, {
    params,
  });
}

export function releaseTracks(params = {}) {
  return ApiContext.get(`creators/releasetracks`, { params });
}

/**
 * Single release
 */

export function releaseSingle(refId, params = {}) {
  return ApiContext.get(`releases/${refId}`, { params });
}

/**
 * Edit release
 */

export function releaseEdit(refId, body, params = {}) {
  return ApiContext.post(`releases/${refId}`, body, { params });
}

/**
 * Single release
 */

export function signedUrl(body, params = {}) {
  return ApiContext.post(`creators/upload/signed_url`, body, { params });
}

export function signedUrlChunk(body, params = {}) {
  return ApiContext.post(`creators/upload/signed_url_chunk`, body, { params });
}
