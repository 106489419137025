import { create } from "zustand";

export const usePodcastStore = create((set) => ({
  loading: true,
  podcasts: {},
  setLoading: (payload) => {
    set((state) => ({ loading: payload }));
  },
  clearItems: () => {
    set((state) => ({ podcasts: {} }));
  },
  addItem: (payload) => {
    set((state) => ({
      podcasts: { ...state.podcasts, [payload.id]: payload },
    }));
  },
  addItems: (payload) => {
    const obj = {};
    Array.from(payload).forEach((item) => {
      obj[item.id] = item;
    });
    set((state) => ({ podcasts: { ...state.podcasts, ...obj } }));
  },
  removeItem: (payload) => {},
}));
