import { connect } from "react-redux";
import { createProfile } from "../../actions/profile";
import component from "../../content/profile/create";

const mapStateToProps = (state, ownProps) => ({
  loading: state.profile.loading,
  createdProfileId: state.profile.createdProfileId,
  profiles: state.profile.profiles || [],
});

const mapDispatchToProps = { createProfile };

export default connect(mapStateToProps, mapDispatchToProps)(component);
