import { connect } from "react-redux";
import { editPodcast, getPodcast } from "../../actions/creators/library";
import { getPodcastCategories as getCategories } from "../../actions/metadata";
import {
  getCategoriesByKind,
  getPodcastById,
} from "../../selectors/PodcastSelectors";
import component from "../../content/podcast/edit";

const mapStateToProps = (state, ownProps) => ({
  loading: state.metadata.loadingCreate,
  podcastId: state.metadata.podcastId,
  profiles: state.profile.profiles || [],
  categories: getCategoriesByKind(state, "podcast"),
  podcast: getPodcastById(state, ownProps.match.params.slug),
});

const mapDispatchToProps = { getCategories, editPodcast, getPodcast };

export default connect(mapStateToProps, mapDispatchToProps)(component);
