import { toast } from "react-toastify";
import {
  chapterCreate,
  chapterEdit,
  chaptersList,
} from "../api/metadataService";
import { getCookie } from "../helpers/cookies";

export const setKeyValue = (propKey, propValue) => ({
  type: "ENTITIES_SET_VALUE",
  propKey,
  propValue,
});

export const addChapter =
  (bodyParams, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { live } = state;

    params["access_token"] = getCookie("access_token");
    dispatch(setKeyValue("loadingCreate", true));
    chapterCreate(bodyParams, params)
      .then((result) => {
        dispatch(setKeyValue("loadingCreate", false));
        toast("Enregistrement efféctué", { type: "success" });

        let response = result.data;
        const { chapter } = response;

        if (chapter) {
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          throw new Error("error occured");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingCreate", false));
        toast("Enregistrement échoué", { type: "success" });
      });
  };

export const editChapter =
  (refId, bodyParams, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { live } = state;

    params["access_token"] = getCookie("access_token");
    dispatch(setKeyValue("loadingCreate", true));
    chapterEdit(refId, bodyParams, params)
      .then((result) => {
        dispatch(setKeyValue("loadingCreate", false));
        toast("Modifications efféctuées", { type: "success" });

        let response = result.data;
        const { chapter } = response;

        if (chapter) {
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          throw new Error("error occured");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingCreate", false));
        toast("Enregistrement échoué", { type: "success" });
      });
  };

export const getChapters =
  (refId, keyName, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { entities } = state;

    params[keyName] = refId;
    params["access_token"] = getCookie("access_token");
    dispatch(setKeyValue("loading", true));
    chaptersList(params)
      .then((result) => {
        dispatch(setKeyValue("loading", false));

        let response = result.data;
        const { chapters } = response;

        if (chapters) {
          let chaptersByPodcast = { ...entities.chaptersByPodcast };
          chaptersByPodcast[refId] = chapters;

          dispatch(setKeyValue("chaptersByPodcast", chaptersByPodcast));
        } else {
          throw new Error("error occured");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loading", false));
        toast("Impossible charger la liste des chapitres / saisons", {
          type: "success",
        });
      });
  };
