import { connect } from "react-redux";
import { getRelease, editRelease } from "../../actions/musicrelease";
import { getCategories } from "../../actions/metadata";
import { getCategoriesByKind } from "../../selectors/PodcastSelectors";
import { getReleaseById } from "../../selectors/ReleaseSelectors";
import component from "../../content/musicrelease/edit";

const mapStateToProps = (state, ownProps) => ({
  loading: state.metadata.loadingCreate,
  podcastId: state.metadata.podcastId,
  profiles: state.profile.profiles || [],
  categories: getCategoriesByKind(state, "music"),
  release: getReleaseById(state, ownProps.match.params.slug),
});

const mapDispatchToProps = { getCategories, editRelease, getRelease };

export default connect(mapStateToProps, mapDispatchToProps)(component);
