/* eslint-disable import/no-anonymous-default-export */
const INITIAL_STATE = {
  transactions: [],
  statistics: [],
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "REPORT_SET_VALUE":
      return { ...state, [action.propKey]: action.propValue };
    case "LOGOUT":
      return INITIAL_STATE;
    default:
      return state;
  }
};
