import { connect } from "react-redux";
import { createTrack } from "../../actions/creators/release";
import component from "../../content/releasetrack/create";

const mapStateToProps = (state, ownProps) => ({
  loadingCreate: state.entities.loadingCreate,
});

const mapDispatchToProps = { createTrack };

export default connect(mapStateToProps, mapDispatchToProps)(component);
