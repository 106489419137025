import React, { Component } from "react";
import Header from "../../components/header/home";
import PageLayout from "../../components/layout/page";
import TextField from "../../components/form/input";
import Select from "../../components/form/select";
import DatePicker from "../../components/form/datepicker";
import Button from "../../components/form/button";
import ProgressBar from "../../components/progress/indeterminate";
import { getCookie } from "../../helpers/cookies";
import { toast } from "react-toastify";

class PageProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profileId: null,
      profileName: "",
      emailAddress: "",
      birthDate: null,
      genderSex: "not_precised",
      addressCountry: "CD",
    };
  }

  componentDidMount() {
    document.title = "Profil - Yoka.cd";
    setTimeout(() => this.fetchProfile(), 500);
  }

  componentDidUpdate(prevProps, prevState) {
    const { profileId, profileName, emailAddress, genderSex, birthDate } =
      this.props;

    if (profileId !== prevState.profileId) {
      this.setState({
        profileName,
        emailAddress,
        genderSex,
        birthDate,
        profileId,
      });
    }
  }

  fetchProfile() {
    let profileId = getCookie("profile_id");

    const { getProfile } = this.props;
    if (getProfile) getProfile(profileId);
  }

  onRequestSubmit() {
    const { profileName, emailAddress, genderSex, birthDate, addressCountry } =
      this.state;
    if (profileName === "" || emailAddress === "" || birthDate === null) {
      toast("Veuillez compléter tous les champs", {
        type: "warning",
        position: "bottom-center",
      });
      return;
    }

    let bodyParams = {
      name: profileName,
      gender_sex: genderSex,
      birth_date: birthDate,
      email_address: emailAddress,
      address_country: addressCountry,
    };

    let profileId = getCookie("profile_id");
    const { editProfile } = this.props;
    if (editProfile) editProfile(profileId, bodyParams);
  }

  render() {
    const {
      profileName,
      emailAddress,
      genderSex,
      addressCountry,
      birthDate,
      loading,
    } = this.props;

    return (
      <div className="h-full bg-white">
        <Header selectedIndex={-1} />
        <PageLayout>
          <div className="bg-white py-10">
            <h1 className="text-4xl font-bold">Modifier le profil</h1>
            <hr className="my-5" />
            {loading && <ProgressBar />}
            <div className="space-y-3">
              <TextField
                nameOrId="profile-name"
                readOnly={loading}
                label={"Nom"}
                placeholder="ex : John Wick"
                value={profileName}
                onChange={(profileName) => this.setState({ profileName })}
              />
              <TextField
                nameOrId="email-address"
                readOnly={loading}
                label={"Adresse email"}
                placeholder="ex : email@example.com"
                value={emailAddress}
                onChange={(emailAddress) => this.setState({ emailAddress })}
              />
              <DatePicker
                disabled={loading}
                nameOrId="birth-date"
                value={birthDate}
                label={"Date de naissance"}
                onChange={(birthDate) => this.setState({ birthDate })}
              />
              <Select
                label={"Sexe"}
                disabled={loading}
                nameOrId="gender-sex"
                value={genderSex}
                onChange={(genderSex) => this.setState({ genderSex })}
                options={[
                  { value: "not_precised", label: "Non précisé" },
                  { value: "male", label: "Homme" },
                  { value: "female", label: "Femme" },
                ]}
              />
              <Select
                disabled={loading}
                nameOrId="address-country"
                label={"Pays ou Région"}
                value={addressCountry}
                options={[{ label: "Congo - Kinshasa", value: "CD" }]}
                onChange={(addressCountry) => this.setState({ addressCountry })}
              />
            </div>
            <hr className="my-5" />
            <div className="flex justify-end space-x-3">
              <div>
                <Button
                  disabled={loading}
                  iconLeft={null}
                  iconRight={null}
                  content="Annuler"
                  onClick={() => {
                    this.props.history.push("/home");
                  }}
                />
              </div>
              <div>
                <Button
                  iconLeft={null}
                  iconRight={null}
                  disabled={loading}
                  content="Enregistrer le profil"
                  buttonStyle={"primary"}
                  onClick={() => this.onRequestSubmit()}
                />
              </div>
            </div>
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default PageProfile;
