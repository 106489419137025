import axios from "axios";
import { API_BASE_HOST } from "../constants/api";
import { removeCookie } from "../helpers/cookies";

const ApiContext = axios.create({
  baseURL: `${API_BASE_HOST}/v1`,
  withCredentials: false,
  timeout: 30000,
  headers: { "Content-Type": "text/plain" },
});

ApiContext.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location.replace("/sign-in?logout=auto");
      removeCookie("access_token");
      removeCookie("account_id");
    }
    return Promise.reject(error.response);
  }
);

/**
 * Signed URL
 */

export function signedUrl(body, params = {}) {
  return ApiContext.post(`uploads/signed_url`, body, { params });
}

/**
 * Signed Chunk URL
 */

export function signedUrls(body, params = {}) {
  return ApiContext.post(`uploads/signed_urls`, body, { params });
}

/**
 * Signed Chunk URL
 */

export function completeMultipartUpload(body, params = {}) {
  return ApiContext.post(`uploads/multipart_complete`, body, { params });
}
