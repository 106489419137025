import React from "react";

export default function Checkbox({
  label,
  checked,
  defaultChecked = false,
  nameOrId = "input_0",
  onChange,
}) {
  return (
    <div class="flex items-center mb-4">
      <input
        id={nameOrId}
        name={nameOrId}
        type="checkbox"
        checked={checked}
        defaultChecked={defaultChecked}
        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
        onChange={(ev) => {
          let checked = ev.target.checked;
          if (onChange) onChange(checked);
        }}
      />
      <label
        for="default-checkbox"
        class="ml-2 text-sm font-medium text-gray-900"
      >
        {label}
      </label>
    </div>
  );
}
