import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/header/home";
import TextField from "../../components/form/input";
import Button from "../../components/form/button";

class PageSignin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountUsername: "",
      accountPassword: "",
    };
  }

  componentDidMount() {
    document.title = "Connexion - Yoka.cd";
  }

  onRequestSubmit() {
    const { accountPassword, accountUsername } = this.state;

    if (accountPassword === "" || accountUsername === "") return;

    let bodyParams = {
      username: accountUsername,
      password: accountPassword,
      auth_provider: "email",
    };

    const { loginUser } = this.props;
    if (loginUser) loginUser(bodyParams);
  }

  render() {
    const { loading } = this.props;
    return (
      <div className="h-screen bg-gradient-to-r from-gray-700 via-gray-900 to-black">
        <Header selectedIndex={-1} />
        <div className="py-10">
          <div className="h-full flex flex-col justify-center items-center">
            <div className="mx-auto md:w-[70%] xl:max-w-2xl 2xl:w-[35rem] border border-gray-100 bg-gray-50 p-20">
              <h1 className="text-4xl font-bold leading-tight my-3 text-center">
                Yoka pour Créateurs de contenu
              </h1>
              <div className="space-y-5 my-4">
                <p className="text-center">
                  Connectez-vous et retrouvez tout votre contenu !
                </p>
              </div>
              <div className="space-y-3">
                <TextField
                  type="email"
                  label={"Adresse email"}
                  placeholder="ex : contact@example.com"
                  onChange={(accountUsername) =>
                    this.setState({ accountUsername })
                  }
                />
                <TextField
                  type="password"
                  label={"Mot de passe"}
                  placeholder="Mot de passe"
                  onChange={(accountPassword) =>
                    this.setState({ accountPassword })
                  }
                />
              </div>
              <div className="space-y-3 mt-10 flex flex-col">
                <Button
                  disabled={loading}
                  content="Connexion"
                  iconRight={null}
                  iconLeft={null}
                  onClick={() => this.onRequestSubmit()}
                />
                <a
                  className="w-full flex items-center justify-center mt-5 px-10 py-2 rounded-full text-primary-500"
                  href={"/password/forgot"}
                >
                  <span className="text-sm font-bold">
                    Mot de passe oublié ?
                  </span>
                </a>
              </div>

              <hr className="my-10" />

              <div className="space-y-3">
                <Link
                  className="w-full flex items-center justify-center mt-5 px-10 py-3 rounded-full text-primary-500 bg-white hover:bg-primary-500 hover:text-white"
                  to={"/sign-up"}
                >
                  <span className="text-sm font-bold">
                    Vous n'avez pas de compte ?
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PageSignin;
