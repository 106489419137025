import { connect } from "react-redux";
import { getCategories } from "../../actions/metadata";
import { getRelease, getTracks } from "../../actions/creators/release";
import {
  getReleaseById,
  getTracksByRelease,
} from "../../selectors/ReleaseSelectors";
import component from "../../content/creators/releasesingle";

const mapStateToProps = (state, ownProps) => ({
  loading: state.entities.loadingCreate,
  createdTrackId: state.entities.createdTrackId,
  editedTrackId: state.entities.editedTrackId,
  release: getReleaseById(state, ownProps.match.params.slug),
  tracks: getTracksByRelease(state, ownProps.match.params.slug),
});

const mapDispatchToProps = { getCategories, getRelease, getTracks };

export default connect(mapStateToProps, mapDispatchToProps)(component);
