/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { Component } from "react";
import { toast } from "react-toastify";
import Plyr from "plyr";
import {
  ArrowUpTrayIcon,
  XMarkIcon,
  ArchiveBoxIcon,
} from "@heroicons/react/24/outline";

import { classNames } from "../../helpers/classNames";

import Button from "../../components/form/button";
import TextField from "../../components/form/input";
import TextArea from "../../components/form/textarea";
import RadioGroup from "../../components/form/radiogroup";
import AudioUploader from "../../components/upload/audio";

class ReleaseTrackEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileUploading: false,
      textUploading: "Aperçu de votre fichier",
      audioFile: null,
      audioUrl: null,
      audioType: null,
      audioFileName: null,
      audioDuration: 0,
      audioMetadata: null,
      wizardMode: "UPLOAD",
      activeTab: "UPLOAD_AUDIO",

      trackTitle: props.track.title,
      trackDescription: props.track.description,
      diskNumber: props.track.disk_number,
      trackNumber: props.track.track_number,
      isContentExplicit: props.track.is_content_explicit,
      isPublished: props.track.is_published,
      epType: props.track.type || "track",
    };

    this.player = null;
  }

  componentDidMount() {
    setTimeout(() => this.initPlayer(), 400);
  }

  initPlayer() {
    let playerElement = document.getElementById("player-preview");
    if (playerElement) this.player = new Plyr(playerElement);
  }

  replacePlayerSource() {
    const { audioUrl, audioFileName, audioType } = this.state;
    if (!this.player) return;

    try {
      this.player.autoplay = true;
      this.player.source = {
        type: "audio",
        title: audioFileName,
        sources: [{ src: audioUrl, type: audioType || "audio/mp3" }],
      };
    } catch (error) {}
  }

  onRequestClose() {
    const { onRequestClose } = this.props;
    if (onRequestClose) onRequestClose();
  }

  onRequestSubmit() {
    const {
      trackTitle,
      trackDescription,
      isContentExplicit,
      epType,
      trackNumber,
      diskNumber,
      audioFile,
      audioDuration,
      audioUrl,
      audioType,
      audioFileName,
      isPublished,
    } = this.state;

    if (trackTitle === "" || trackDescription === "") {
      toast("Veuillez remplir tous les champs réquis", {
        position: "bottom-center",
        type: "error",
      });
      return;
    }

    let bodyRequest = {
      title: trackTitle,
      description: trackDescription,
      is_content_explicit: isContentExplicit,
      is_published: isPublished,
      type: epType,
      track_number: trackNumber !== "" ? trackNumber : null,
      disk_number: diskNumber !== "" ? diskNumber : null,
      audio: null,
    };
    if (audioFile) {
      bodyRequest.audio = {
        url: audioUrl,
        type: audioType,
        duration: audioDuration,
        filename: audioFileName,
      };
    }

    const { editTrack, track } = this.props;
    if (editTrack) editTrack(track.id, bodyRequest);
  }

  render() {
    const {
      pageTitle = "Modifier votre piste audio",
      aboutText = "Informations sur la piste audio",
    } = this.props;
    const {
      activeTab,
      fileUploading,
      textUploading,
      trackTitle,
      trackDescription,
      isContentExplicit,
      isPublished,
      diskNumber,
      trackNumber,
    } = this.state;
    return (
      <div>
        <div className="flex space-x-2 justify-between">
          <h1 className="text-4xl font-bold">{pageTitle}</h1>
          <a href="javascript:void(0);" onClick={() => this.onRequestClose()}>
            <XMarkIcon className="w-10 h-10 text-gray-400" />
          </a>
        </div>
        <hr className="my-5" />
        <div className="flex space-x-10">
          <div className="w-[45%]">
            <div className="border-[0.5px] px-10 py-5 rounded">
              <h1>{trackTitle || textUploading}</h1>
              <hr className="my-5" />
              <audio
                controls={true}
                id={"player-preview"}
                style={{ width: "100%" }}
              >
                {/** <source src={audioUrl} type="audio/mp3" /> */}
              </audio>
            </div>

            <div className="flex space-x-4 bg-white py-10">
              <div className="space-y-4 flex flex-col">
                {[
                  {
                    key: "UPLOAD_AUDIO",
                    title: "Uploader un audio",
                    leftIcon: <ArrowUpTrayIcon className="w-5 h-5" />,
                    onClick: () => this.setState({ activeTab: "UPLOAD_AUDIO" }),
                  },
                  {
                    key: "LIBRARY_AUDIO",
                    title: "Choisir depuis la bibliothèque",
                    leftIcon: <ArchiveBoxIcon className="w-5 h-5" />,
                    onClick: () =>
                      this.setState({ activeTab: "LIBRARY_AUDIO" }),
                  },
                ].map((item, x) => (
                  <a href="javascript:void(0);" onClick={item.onClick}>
                    <div
                      className={classNames(
                        "w-[19rem] p-6 py-4 border-[1px] border-gray-300 rounded flex items-center space-x-3",
                        activeTab === item.key && " bg-slate-200"
                      )}
                    >
                      {item.leftIcon}
                      <h1 className="font-medium text-sm">{item.title}</h1>
                    </div>
                  </a>
                ))}
              </div>
              <div className="w-full border-l pl-5">
                {activeTab === "UPLOAD_AUDIO" && (
                  <AudioUploader
                    onGotUrl={(audioUrl) =>
                      this.setState({ audioUrl }, () => {
                        this.replacePlayerSource();
                      })
                    }
                    onGotMetadata={(info) => {
                      this.setState({
                        audioType: info.type,
                        audioFile: info.file,
                        audioFileName: info.name,
                        audioDuration: info.duration,
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="w-[55%]">
            <h1 className="font-semibold text-2xl">{aboutText}</h1>
            <p>
              Ajoutez des informations et définissez le mode de publication.
            </p>
            <div className="my-5 space-y-3">
              <TextField
                nameOrId="episode-name"
                label={"Titre de l'épisode *"}
                placeholder="Titre"
                value={trackTitle}
                onChange={(trackTitle) => this.setState({ trackTitle })}
              />
              <TextArea
                nameOrId="episode-description"
                label={"Description de l'épisode *"}
                placeholder="Entrez une brève description"
                value={trackDescription}
                onChange={(trackDescription) =>
                  this.setState({ trackDescription })
                }
              />
              <RadioGroup
                label={"Votre épisode comporte-t-il du contenu explicite ?"}
                nameOrId="explicit-content"
                options={[
                  {
                    label: "Oui",
                    value: "include_explicit_content",
                    checked: isContentExplicit === true,
                    onChecked: () => this.setState({ isContentExplicit: true }),
                  },
                  {
                    label: "Non",
                    value: "no_explicit_content",
                    checked: isContentExplicit === false,
                    onChecked: () =>
                      this.setState({ isContentExplicit: false }),
                  },
                ]}
              />

              <RadioGroup
                label={"Disponibilité dans Yoka"}
                nameOrId="publish-state"
                options={[
                  {
                    label: "Publié",
                    value: "is_published",
                    checked: isPublished === true,
                    onChecked: () => this.setState({ isPublished: true }),
                  },
                  {
                    label: "Brouillon",
                    value: "is_not_published",
                    checked: isPublished === false,
                    onChecked: () => this.setState({ isPublished: false }),
                  },
                ]}
              />

              <div className="flex space-x-5">
                <TextField
                  type="number"
                  nameOrId="episode-season"
                  label="Nméro du disque"
                  placeholder={"ex : 1"}
                  value={diskNumber}
                  onChange={(diskNumber) => this.setState({ diskNumber })}
                />
                <TextField
                  type="number"
                  nameOrId="episode-number"
                  label="Numéro de la piste"
                  placeholder={"ex : 5"}
                  value={trackNumber}
                  onChange={(trackNumber) => this.setState({ trackNumber })}
                />
              </div>
            </div>
          </div>
        </div>

        <hr />
        <div className="flex justify-between pt-2">
          <div>
            <Button
              disabled={fileUploading}
              iconLeft={null}
              iconRight={null}
              content="Quitter"
              onClick={() => this.onRequestClose()}
            />
          </div>
          <div>
            <Button
              buttonStyle={"primary"}
              disabled={fileUploading}
              iconLeft={null}
              iconRight={null}
              content="Enregistrer"
              onClick={() => this.onRequestSubmit()}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ReleaseTrackEdit;
