import React, { useCallback, useState } from "react";
import Chunk from "../../helpers/ChunkAWS";
import { toast } from "react-toastify";
import { fileToBlob, revokeObjectURL } from "../../utils/FileUtil";
import { MicrophoneIcon } from "@heroicons/react/24/outline";
import { Button, ProgressBar } from "@tremor/react";

export default function AudioRecorder({ onGotMetadata, onGotUrl }) {
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [progressVisible, setProgressVisible] = useState(false);

  const onFileChange = (evt) => {
    let files = evt.target.files;
    const [firstFile] = files;

    // get metadata
    getMetadata(firstFile);

    // get metadata
    uploadFile(firstFile);

    let picker = document.getElementById("library-picker");
    if (picker) picker.value = null;
  };

  const getMetadata = (file) => {
    let blobUrl = fileToBlob(file);
    let audioElement = document.createElement("audio");
    audioElement.src = blobUrl;
    audioElement.addEventListener(
      "loadedmetadata",
      () => {
        var duration = audioElement.duration;

        if (onGotMetadata) {
          onGotMetadata({ file, duration, type: file.type, name: file.name });
        }
        revokeObjectURL(blobUrl);
      },
      false
    );
  };

  const uploadFile = (file) => {
    let PART_SIZE = 5 * 1024 * 1024;

    setLoading(true);
    setProgressVisible(true);
    const chunk = new Chunk({
      name: "file", // request name
      size: PART_SIZE, // chunk size
      onUploadPart: (blob) => {},
      onProgress: (progress) => {
        console.log(`${progress}% uploaded...`);
        setProgress(progress);
      },
      onError: (error) => {
        console.log(`error occured : ${error}`);
        setLoading(false);
        setProgressVisible(false);

        toast("Téléchargement échoué", { type: "error" });
      },
      onFinished: (data) => {
        console.log(`finished : ${data}`);

        setLoading(false);
        setProgressVisible(false);
        toast("Téléchargement réussi", { type: "success" });

        const { publicUrl } = data;
        if (onGotUrl) onGotUrl(publicUrl);
      },
    });
    chunk.setFile(file);
    chunk.commit();
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center border border-dashed border-gray-400 rounded-md h-[30rem] p-10 space-y-5">
        <MicrophoneIcon className="w-24 h-24 text-gray-400" />
        <Button
          loading={loading}
          disabled={loading}
          variant="light"
          iconPosition={"left"}
          icon={MicrophoneIcon}
          onClick={() => {}}
        >
          Débuter l'enregistrement
        </Button>
        {progressVisible && <ProgressBar value={progress} className="mt-2" />}
      </div>
    </>
  );
}
