import React, { Component } from "react";
const FOOTER_LOGO = require("../../../assets/img/logos/logo-white-text.png");

const menuItems = [
  {
    text: "Accueil",
    description: "Accueil",
    href: "/",
  },
  {
    text: "A propos",
    description: "A propos",
    href: "/",
  },
  {
    text: "Application",
    description: "Services",
    href: "/",
  },
  {
    text: "Contact",
    description: "Decouvrez nos produits",
    href: "/",
  },
];

class FooterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <footer className="mx-auto w-full max-w-container bg-white">
        <div className="container mx-auto py-10 flex flex-col md:flex-row space-y-5 md:space-y-0">
          <div className="flex justify-center md:justify-start">
            <img src={FOOTER_LOGO} className={"h-28"} alt={"JUU"} />
          </div>
          <div className="flex-1 flex md:border-l md:border-gray-300 md:mx-10 md:px-16 px-10">
            <div className="flex-1">
              <h1 className="font-bold font-hkgrotesk">Accès Rapide</h1>
              <ul className="space-y-2 mt-5">
                {menuItems.map((item, x) => (
                  <li>
                    <a key={x} className="hover:text-primary2" href={item.href}>
                      <span className="text-sm font-medium">{item.text}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="justify-center md:justify-start hidden xl:inline">
            <h1 className="font-bold font-hkgrotesk">Notre adresse</h1>
            <div>
              <span className="text-sm">123, Avenue, Suite, Immeuble</span>
            </div>
            <div>
              <span className="text-sm">CP, 0124</span>
            </div>
            <div>
              <span className="text-sm">Kinshasa - Gombe</span>
            </div>
            <div className="pt-5 flex space-x-3 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 27 28"
                width={27}
                height={27}
              >
                <path
                  fill="currentColor"
                  d="M14.83 27.33V17h3.06l.36-2.33h-3.42v-3.04a2.5 2.5 0 0 1 2.67-2.67h.93V7.11c-.72-.1-1.44-.15-2.17-.16a3.81 3.81 0 0 0-4.1 4.26v3.46h-3.2V17h3.2v10.33l-.77-.12a13.33 13.33 0 1 1 4.16 0l-.72.12ZM13.5 2a12 12 0 0 0-2.67 23.7v-7.37h-3.2v-5h3.2v-2.12a5.12 5.12 0 0 1 5.44-5.6c1 .01 1.98.1 2.96.26l.55.09v4.35H17.5c-.95 0-1.33.42-1.33 1.33v1.7h3.6l-.76 5h-2.84v7.35A12 12 0 0 0 13.5 2Z"
                />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 24"
                width={27}
                height={27}
              >
                <path
                  fill="currentColor"
                  d="M11.69 23.7c-3.1 0-6.14-.89-8.75-2.57L.5 19.56l2.88.35c2.16.25 4.35-.18 6.25-1.23a6.12 6.12 0 0 1-4-4l-.34-1.07.58.11a6.16 6.16 0 0 1-2.2-4.7V7.87l1 .5A6.16 6.16 0 0 1 4.55 2l.48-.84.62.75c2.48 3.05 6.06 4.99 9.97 5.41V6.8a6.15 6.15 0 0 1 10.35-4.47c1.02-.24 2-.63 2.92-1.17l1.52-.9-.55 1.67a5.59 5.59 0 0 1-.79 1.6l.63-.25 2.1-.93-1.34 1.92c-.73 1.06-1.63 2-2.67 2.78v.4a16.06 16.06 0 0 1-16.1 16.24Zm-5.72-2.47A14.88 14.88 0 0 0 26.54 7.47V6.43l.3-.22c.4-.29.77-.6 1.13-.94-.36.08-.72.13-1.08.17l-3.1.37 2.67-1.6c.43-.26.81-.58 1.15-.96-.57.21-1.16.37-1.75.48l-.36.08-.25-.28a4.81 4.81 0 0 0-8.19 4.4l.2.86h-.88A16.25 16.25 0 0 1 5.3 3.63a4.99 4.99 0 0 0 .32 3.8c.38.7.92 1.3 1.57 1.73l1.94 1.33H6.8a6.43 6.43 0 0 1-1.68-.29 4.81 4.81 0 0 0 3.73 3.72l2.76.55-2.67.74c-.51.14-1.05.21-1.58.22a4.83 4.83 0 0 0 4 2.37h1.88l-1.48 1.16a11.41 11.41 0 0 1-5.8 2.27Z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 27 28"
                width={27}
                height={27}
              >
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M19.83.67H7.17a7.01 7.01 0 0 0-7 7v12.66a7.01 7.01 0 0 0 7 7h12.66a7.01 7.01 0 0 0 7-7V7.67a7.01 7.01 0 0 0-7-7Zm5.67 19.66A5.68 5.68 0 0 1 19.83 26H7.17a5.68 5.68 0 0 1-5.67-5.67V7.67A5.68 5.68 0 0 1 7.17 2h12.66a5.68 5.68 0 0 1 5.67 5.67v12.66ZM13.5 8a6 6 0 1 0 0 12 6 6 0 0 0 0-12Zm0 10.67a4.67 4.67 0 1 1 0-9.34 4.67 4.67 0 0 1 0 9.34Zm7.1-12.62a.65.65 0 0 1 .26-.05h.01a.67.67 0 1 1-.66.67.66.66 0 0 1 .4-.62Z"
                  clipRule="evenodd"
                />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 21 15"
                width={27}
                height={27}
              >
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M19.91 2.07c.31.31.53.7.65 1.12.59 3.17.59 6.43 0 9.6a2.5 2.5 0 0 1-1.77 1.76c-2.5.36-5.01.5-7.53.43-2.7.06-5.4-.08-8.09-.43a2.48 2.48 0 0 1-1.76-1.76 27.83 27.83 0 0 1 0-9.59 2.52 2.52 0 0 1 1.76-1.78 47.1 47.1 0 0 1 7.69-.43c2.65-.08 5.3.07 7.93.43.42.12.8.34 1.12.65ZM19.2 13.2c.17-.19.3-.41.36-.66h.04c.55-3.04.55-6.15-.01-9.19a1.5 1.5 0 0 0-1.06-1h-.1c-2.51-.3-5.04-.41-7.57-.35-2.5-.03-5 .14-7.47.51a1.49 1.49 0 0 0-1 1c-.51 3-.51 6.08 0 9.09a1.51 1.51 0 0 0 1.05 1 55 55 0 0 0 7.83.39c2.44.07 4.88-.07 7.29-.4.24-.07.46-.2.64-.4ZM15.3 8l-6.74 3.86V4.13l6.73 3.86Zm-2.02 0L9.56 5.85v4.27l3.72-2.13Z"
                  clipRule="evenodd"
                />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 20"
                width={27}
                height={27}
              >
                <path
                  fill="currentColor"
                  d="M11.8 1v.14a6 6 0 0 0 4.96 4.79v1.26a7.28 7.28 0 0 1-3.37-1.44l-1.67-1.26v9.29a5.1 5.1 0 0 1-1.54 3.64 5.31 5.31 0 0 1-6.95.44 5.25 5.25 0 0 1-2-4.43 5.15 5.15 0 0 1 3.06-4.4c.67-.3 1.4-.46 2.14-.46V9.8a4 4 0 0 0-1.97 7.4 4 4 0 0 0 1.8.56h.24a4 4 0 0 0 3.95-3.95V1h1.36Zm.92-1H9.45v13.81a3 3 0 0 1-2 2.8c-.3.1-.6.15-.92.15h-.21A3 3 0 0 1 5 16.34a3 3 0 0 1 1.52-5.49c.32 0 .63.05.93.15V7.69A6.28 6.28 0 0 0 1.8 9.72a6.16 6.16 0 0 0 .87 8.98 6.27 6.27 0 0 0 10.07-4.86v-7.3a8.11 8.11 0 0 0 5.05 1.71V5a5.06 5.06 0 0 1-4.96-4c-.07-.33-.1-.66-.1-1h-.01Z"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="border-t border-slate-900/5 py-10 flex flex-col items-center px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-center space-x-4 text-sm font-semibold leading-6 text-white">
            <a href="/privacy-policy">Termes de confidentialité</a>
            <div className="h-4 w-px bg-white" />
            <a href="/termsofuse">Conditions d'utilisation</a>
          </div>
        </div>
      </footer>
    );
  }
}

export default FooterPage;
