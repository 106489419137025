import React from "react";
import { Link } from "react-router-dom";
const defaultCover = require("../../../assets/img/covers/sample-cover.jpg");

const Podcast = ({
  name = "Podcast Name",
  image = defaultCover,
  linkTo = "/",
}) => {
  return (
    <div className="">
      <Link className="" to={linkTo}>
        <div>
          <figure className="w-[15rem] h-[17rem] bg-gray-100 flex flex-col rounded-sm shadow-md overflow-auto relative">
            <img
              src={image}
              alt={name}
              className="w-full h-full object-cover"
            />
            <div className="absolute bottom-0 left-0 flex flex-col items-center justify-center p-5">
              <button
                type="button"
                class="h-10 w-10 rounded-full bg-white flex flex-col justify-center items-center"
                aria-label="Écouter"
              >
                <svg
                  class="svg-icon svg-icon-play"
                  focusable="false"
                  height="1em"
                  role="img"
                  width="1em"
                  viewBox="0 0 12 12"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                    d="M2.5.5v11l9-5.5z"
                  ></path>
                </svg>
              </button>
            </div>
          </figure>

          <div className="py-5">
            <h1 className="font-medium text-md">{name}</h1>
          </div>
        </div>
      </Link>
    </div>
  );
};

Podcast.propTypes = {};

export default Podcast;
