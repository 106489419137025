import { connect } from "react-redux";
import { getProfiles } from "../../actions/profile";
import { getReleases } from "../../actions/creators/release";
import component from "../../content/library/music";

const mapStateToProps = (state, ownProps) => ({
  loading: state.profile.loading,
  profiles: state.profile.profiles || [],
  releases: state.entities.releases || [],
  loadingReleases: state.entities.loadingReleases || false,
});

const mapDispatchToProps = {
  getProfiles,
  getReleases,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
