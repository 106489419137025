/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import QS from "query-string";
import { toast } from "react-toastify";
import Header from "../../components/header/home";
import PageLayout from "../../components/layout/page";
import TextField from "../../components/form/input";
import Button from "../../components/form/button";
import ProgressBar from "../../components/progress/indeterminate";
import Select from "../../components/form/select";
import TextArea from "../../components/form/textarea";
import Avatar from "../account/avatar";
import { classNames } from "../../helpers/classNames";
import {
  CogIcon,
  ClipboardIcon,
  InformationCircleIcon,
  MicrophoneIcon,
} from "@heroicons/react/24/outline";
import RadioGroup from "../../components/form/radiogroup";

class PageEdit extends Component {
  constructor(props) {
    super(props);

    let parsedQuery = QS.parse(window.location.search);
    const { profile_id } = parsedQuery;

    this.state = {
      releaseId: null,
      profile_id,
      photo_url: null,
      topic: "learning", // [preach,podcast]
      type: "album", // [serial,episodic]
      country_code: "cd",
      title: "",
      description: "",
      copyright: "",
      is_content_explicit: false,
      is_fund_enabled: false,
      primary_genre_id: "none-selected",
      release_date: null,
      facebook_url: "",
      instagram_url: "",
      twitter_url: "",
      youtube_url: "",
      genres_ids: [],
      activeTab: "TAB_HOME",
    };
  }

  componentDidMount() {
    document.title = "Paramètres de la sortie - Yoka.cd";

    this.fetchData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { release } = this.props;
    if (release) {
      if (release.id !== prevState.releaseId) {
        this.setState({
          type: "episodic", // [serial,episodic]
          country_code: "cd",
          title: release.title,
          description: release.description,
          copyright: release.copyright,
          release_date: release.release_date,
          release_date_precision: release.release_date_precision,
          is_content_explicit: release.is_content_explicit,
          is_fund_enabled: release.is_fund_enabled,
          is_published: release.is_published || false,
          primary_language: release.primary_language || "en",
          primary_genre_id: release.primary_genre_id,
          facebook_url: release.facebook_url || "",
          instagram_url: release.instagram_url || "",
          twitter_url: release.twitter_url || "",
          youtube_url: release.youtube_url || "",
          genres_ids: release.genres_ids || [],
          photo_url: release.photo_url || null,
          releaseId: release.id,
        });
      }
    }
  }

  fetchData() {
    const { getCategories, getRelease, match } = this.props;
    if (getCategories) getCategories("music", {});
    if (getRelease) getRelease(match.params.slug);
  }

  onCategorySelected(value) {
    let { genres_ids } = this.state;

    let index = genres_ids.findIndex((item) => item === value);
    if (index > 0) {
      genres_ids = genres_ids.filter((_, x) => x !== index);
    } else {
      genres_ids = [...genres_ids, value];
    }

    this.setState({ genres_ids });
  }

  onRequestSubmit() {
    const {
      profile_id,
      type,
      title,
      description,
      photo_url,
      country_code,
      primary_language,
      primary_genre_id,
      facebook_url,
      instagram_url,
      twitter_url,
      youtube_url,
      genres_ids,
      is_content_explicit,
      is_fund_enabled,
      is_published,
      copyright,
      release_date,
    } = this.state;

    if (title === "" || description === "") {
      toast("Veuillez compléter tous les champs", {
        type: "warning",
        position: "bottom-center",
      });
      return;
    }

    let bodyParams = {
      profile_id,
      title,
      type,
      description,
      copyright,
      country_code,
      photo_url,
      primary_genre_id,
      facebook_url,
      instagram_url,
      twitter_url,
      youtube_url,
      genres_ids,
      is_content_explicit,
      primary_language,
      is_fund_enabled,
      is_published,
      release_date,
      release_date_precision: "date",
    };

    const { editRelease, match } = this.props;
    if (editRelease) editRelease(match.params.slug, bodyParams);
  }

  render() {
    const {
      type,
      title,
      description,
      copyright,
      photo_url,
      country_code,
      primary_language,
      primary_genre_id,
      facebook_url,
      instagram_url,
      twitter_url,
      youtube_url,
      genres_ids,
      is_content_explicit,
      is_fund_enabled,
      is_published,
      activeTab,
    } = this.state;
    const { loading, match, categories: categories_select } = this.props;

    return (
      <div className="h-full bg-white">
        <Header selectedIndex={-1} />
        <PageLayout>
          <div className="bg-white py-10">
            <h1 className="text-4xl font-bold">Paramètres de la sortie</h1>
            <hr className="my-5" />
            {loading && <ProgressBar />}
            <div className="flex space-x-5">
              <div className="space-y-4 flex flex-col">
                {[
                  {
                    key: "TAB_HOME",
                    title: "Informations",
                    leftIcon: <InformationCircleIcon className="w-5 h-5" />,
                    onClick: () => this.setState({ activeTab: "TAB_HOME" }),
                  },
                  {
                    key: "TAB_DISTRIBUTE",
                    title: "Disponibilité du contenu",
                    leftIcon: <MicrophoneIcon className="w-5 h-5" />,
                    onClick: () =>
                      this.setState({ activeTab: "TAB_DISTRIBUTE" }),
                  },
                  {
                    key: "TAB_SETTINGS",
                    title: "Plus d'action",
                    leftIcon: <CogIcon className="w-5 h-5" />,
                    onClick: () => this.setState({ activeTab: "TAB_SETTINGS" }),
                  },
                ].map((item, x) => (
                  <a href="javascript:void(0);" onClick={item.onClick}>
                    <div
                      className={classNames(
                        "w-[19rem] p-10 py-4 border-[1px] border-gray-300 rounded flex items-center space-x-3",
                        activeTab === item.key && " bg-slate-200"
                      )}
                    >
                      {item.leftIcon}
                      <h1 className="font-medium text-sm">{item.title}</h1>
                    </div>
                  </a>
                ))}
              </div>

              <div className="space-y-4 w-full">
                {activeTab === "TAB_DISTRIBUTE" && (
                  <>
                    <div className="space-y-4">
                      <TextField
                        readOnly={true}
                        nameOrId="link-yoka"
                        label={"Lien Yoka"}
                        value={`https://yoka.cd/podcast/${match.params.slug}`}
                        suffix={
                          <div className="absolute inset-y-0 right-0 flex flex-col items-center justify-center px-3">
                            <a href="javascript:void(0);" onClick={() => {}}>
                              <ClipboardIcon className="w-4 h-4" />
                            </a>
                          </div>
                        }
                      />
                      <TextField
                        readOnly={true}
                        nameOrId="link-rss"
                        label={"Lien RSS"}
                        value={`https://yoka.cd/rss/${match.params.slug}`}
                        suffix={
                          <div className="absolute inset-y-0 right-0 flex flex-col items-center justify-center px-3">
                            <a href="javascript:void(0);" onClick={() => {}}>
                              <ClipboardIcon className="w-4 h-4" />
                            </a>
                          </div>
                        }
                      />
                    </div>
                  </>
                )}
                {activeTab === "TAB_SETTINGS" && (
                  <div>
                    <RadioGroup
                      label={"Disponibilité dans Yoka"}
                      nameOrId="publish-state"
                      options={[
                        {
                          label: "Publié",
                          value: "is_published",
                          checked: this.state.is_published === true,
                          onChecked: () =>
                            this.setState({ is_published: true }),
                        },
                        {
                          label: "Brouillon",
                          value: "is_not_published",
                          checked: this.state.is_published === false,
                          onChecked: () =>
                            this.setState({ is_published: false }),
                        },
                      ]}
                    />
                  </div>
                )}
                {activeTab === "TAB_HOME" && (
                  <>
                    <div className="flex space-x-5">
                      <div className="space-y-4 w-1/2">
                        <TextField
                          readOnly={loading}
                          nameOrId="profile-name"
                          placeholder="Choisissez bien le titre de la sortie"
                          label={"Titre de la sortie"}
                          value={title}
                          onChange={(title) => this.setState({ title })}
                        />
                        <TextArea
                          label={"Description de la sortie"}
                          placeholder="Quel est le sujet de votre sortie ?"
                          value={description}
                          onChange={(description) =>
                            this.setState({ description })
                          }
                        />
                        <TextField
                          readOnly={loading}
                          nameOrId="release-label"
                          label={"Label"}
                          placeholder="ex: Universal Music Africa"
                          value={copyright}
                          onChange={(copyright) => this.setState({ copyright })}
                        />
                        {/** <Select
                          disabled={loading}
                          nameOrId="address-country"
                          label={"Pays ou Région"}
                          value={country_code}
                          options={[{ label: "Congo - Kinshasa", value: "CD" }]}
                          onChange={(country_code) =>
                            this.setState({ country_code })x
                          }
                        /> */}
                        <Select
                          disabled={loading}
                          nameOrId="podcast-language"
                          label={"Langue"}
                          value={primary_language}
                          options={[
                            { label: "Français", value: "fr" },
                            { label: "Anglais", value: "en" },
                          ]}
                          onChange={(primary_language) =>
                            this.setState({ primary_language })
                          }
                        />
                        <Select
                          disabled={loading}
                          nameOrId="podcast-category"
                          label={"Genre principal"}
                          value={primary_genre_id}
                          options={[
                            { label: "Sélectionner", value: "none-selected" },
                            ...categories_select,
                          ]}
                          onChange={(primary_genre_id) =>
                            this.setState({
                              primary_genre_id,
                              genres_ids: [primary_genre_id],
                            })
                          }
                        />

                        {primary_genre_id !== "none-selected" && (
                          <div className="mt-5 space-y-3">
                            <span className="text-sm font-semibold text-gray-700">
                              Ajouter d'autres Genres
                            </span>
                            <div className="flex flex-wrap">
                              {categories_select
                                .filter((_, x) => x < 35)
                                .map((item, x) => (
                                  <div
                                    key={x}
                                    onClick={() =>
                                      this.onCategorySelected(item.value)
                                    }
                                    className={classNames(
                                      "md:mr-2 mb-2 cursor-pointer px-3 py-1 border-[1.5px] rounded-full",
                                      genres_ids.includes(item.value)
                                        ? " border-primary-400 bg-primary-400 text-white"
                                        : " border-gray-400 "
                                    )}
                                  >
                                    <span className="text-sm font-medium">
                                      {item.label}
                                    </span>
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}

                        <div className="py-5">
                          <hr className="" />
                        </div>
                        <TextField
                          readOnly={loading}
                          nameOrId="twitter_url"
                          label={"Lien page Twitter"}
                          placeholder="ex : https://twitter.com/pagexxxx"
                          value={twitter_url}
                          onChange={(twitter_url) =>
                            this.setState({ twitter_url })
                          }
                        />
                        <TextField
                          readOnly={loading}
                          nameOrId="instagram_url"
                          label={"Lien page instagram"}
                          placeholder="ex : https://instagram.com/pagexxxx"
                          value={instagram_url}
                          onChange={(instagram_url) =>
                            this.setState({ instagram_url })
                          }
                        />
                        <TextField
                          readOnly={loading}
                          nameOrId="youtube_url"
                          label={"Lien page youtube"}
                          placeholder="ex : https://youtube.com/pagexxxx"
                          value={youtube_url}
                          onChange={(youtube_url) =>
                            this.setState({ youtube_url })
                          }
                        />
                        <TextField
                          readOnly={loading}
                          nameOrId="facebook_url"
                          label={"Lien page instagram"}
                          placeholder="ex : https://facebook.com/pagexxxx"
                          value={facebook_url}
                          onChange={(facebook_url) =>
                            this.setState({ facebook_url })
                          }
                        />
                      </div>

                      <div className="w-1/2">
                        <div className="p-10 md:pt-6">
                          <Avatar
                            buttonText="Pochette du podcast"
                            image={photo_url}
                            imageUploaded={(photo_url) => {
                              this.setState({ photo_url });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <hr className="my-5" />
            <div className="flex justify-between space-x-3">
              <div>
                <Button
                  disabled={loading}
                  iconLeft={null}
                  iconRight={null}
                  content="Annuler"
                  onClick={() => {
                    this.props.history.push(`/music/${match.params.slug}`);
                  }}
                />
              </div>
              <div>
                <Button
                  iconLeft={null}
                  iconRight={null}
                  disabled={loading}
                  content="Modifier votre sortie"
                  buttonStyle={"primary"}
                  onClick={() => this.onRequestSubmit()}
                />
              </div>
            </div>
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default PageEdit;
