import { connect } from "react-redux";
import { getCategories } from "../../actions/metadata";
import { createRelease } from "../../actions/creators/release";
import { getCategoriesByKind } from "../../selectors/PodcastSelectors";
import component from "../../content/creators/releasecreate";

const mapStateToProps = (state, ownProps) => ({
  loading: state.metadata.loadingCreate,
  createdReleaseId: state.entities.createdReleaseId,
  profiles: state.profile.profiles || [],
  categories: getCategoriesByKind(state, "music"),
});

const mapDispatchToProps = { getCategories, createRelease };

export default connect(mapStateToProps, mapDispatchToProps)(component);
