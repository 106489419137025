import { useEffect, useMemo, useState } from "react";
import { getCookie } from "../helpers/cookies";
import { profileList } from "../api/userService";

export default function useProfiles() {
  const [loading, setLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    const access_token = getCookie("access_token");
    const kind = "podcaster,artist,preacher,learner";

    setLoading(true);
    profileList({ access_token, kind })
      .then((result) => {
        const response = result.data;
        const { profiles: _profiles } = response;
        if (_profiles) {
          setProfiles(_profiles);
        } else {
          throw new Error("error retrieving data");
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
    return () => {};
  }, []);

  return useMemo(() => ({ profiles }), [profiles]);
}
