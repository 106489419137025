/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import {
  Squares2X2Icon,
  MicrophoneIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Tab, TabGroup, TabPanels, TabPanel, TabList } from "@tremor/react";
import Header from "../../components/header/home";
import Button from "../../components/form/button";
import Table from "../../components/table/table";
import PageLayout from "../../components/layout/page";
import AnalyticData from "../../components/analytics/minimal";
import CreateEpisode from "./episodeCreate";
import EditEpisode from "./episodeEdit";
import SeasonCreate from "../season/create";
import ChapterEdit from "../season/edit";

class PagePodcast extends Component {
  constructor(props) {
    super(props);

    this.state = {
      wizardVisible: false,
      wizardEditVisible: false,
      seasonCreateVisible: false,
      seasonEditVisible: false,
      selectedEpisode: null,
      selectedSeason: null,
    };
  }

  componentDidMount() {
    document.title = "Cours - Yoka.cd";
    setTimeout(() => this.fetchPodcast(), 500);
  }

  fetchPodcast() {
    const { getPodcast, getPodcastEpisodes, getChapters, match } = this.props;
    if (getPodcast) getPodcast(match.params.slug);
    if (getChapters) getChapters(match.params.slug, "podcast_id");
    if (getPodcastEpisodes) getPodcastEpisodes(match.params.slug);
  }

  render() {
    const {
      loadingCreate,
      podcast,
      episodes = [],
      chapters,
      match,
    } = this.props;
    const {
      wizardVisible,
      wizardEditVisible,
      selectedEpisode,
      selectedSeason,
      seasonCreateVisible,
      seasonEditVisible,
    } = this.state;

    return (
      <div className="h-full bg-white">
        <Header selectedIndex={-1} />
        <PageLayout>
          <div className="bg-white py-10 relative">
            <div className="flex space-x-3">
              <div className="w-32 h-32 bg-gray-100">
                {podcast && (
                  <img
                    alt={podcast.title}
                    src={podcast.photo_url}
                    className="h-full w-full object-cover"
                  />
                )}
              </div>
              {podcast && (
                <h1 className="text-4xl font-bold">{podcast.title}</h1>
              )}
              {!podcast && <h1 className="text-4xl font-bold">Cours</h1>}
            </div>
            <hr className="my-5" />
            <div className="grid md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 my-10">
              {[
                {
                  title: podcast ? podcast.start_position : "0",
                  sub_title: "Classement",
                },
                {
                  title: podcast ? podcast.stream_count : "0",
                  sub_title: "Ecoutes",
                },
                {
                  title: podcast ? podcast.listener_count : "0",
                  sub_title: "Auditeurs",
                },
                {
                  title: podcast ? podcast.subscriber_count : "0",
                  sub_title: "Abonnés",
                },
              ].map((item, x) => (
                <AnalyticData
                  key={x}
                  title={item.title}
                  sub_title={item.sub_title}
                />
              ))}
            </div>
            <hr className="my-5" />

            <TabGroup>
              <TabList className="mt-8">
                <Tab icon={MicrophoneIcon}>Leçons</Tab>
                <Tab icon={Squares2X2Icon}>Chapitres</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <h1 className="text-xl my-10 font-bold">Leçons</h1>
                  <Table
                    dataSource={episodes}
                    columns={[
                      {
                        field: "title",
                        title: `${episodes.length} Leçons`,
                        renderElement: (record) => (
                          <div class="flex items-center">
                            {record.is_published && (
                              <span class="bg-green-500 h-2 w-2 rounded-full mr-2"></span>
                            )}
                            {!record.is_published && (
                              <span class="bg-gray-500 h-2 w-2 rounded-full mr-2"></span>
                            )}
                            <span class="font-normal">{record.title}</span>
                          </div>
                        ),
                      },
                      { field: "stream_count", title: "Ecoutes", width: "10%" },
                      {
                        field: "listener_count",
                        title: "Auditeurs",
                        width: "10%",
                      },
                      {
                        field: "published_at",
                        title: "Date publication",
                        width: "5%",
                        srOnly: true,
                        renderElement: (record) => (
                          <div class="flex items-center space-x-5">
                            <a
                              href="javascript:void(0);"
                              onClick={() => {
                                this.setState({
                                  selectedEpisode: record,
                                  wizardEditVisible: true,
                                });
                              }}
                            >
                              <PencilIcon className="w-4 h-4" />
                            </a>
                            <a href="javascript:void(0);" onClick={() => {}}>
                              <TrashIcon className="w-4 h-4 text-red-500" />
                            </a>
                          </div>
                        ),
                      },
                    ]}
                  />

                  <div className="flex py-10 space-x-4">
                    <div>
                      <Button
                        content="Ajouter une leçon"
                        iconLeft={null}
                        iconRight={null}
                        onClick={() => this.setState({ wizardVisible: true })}
                      />
                    </div>
                    <div>
                      <Button
                        content="Paramètres du cours"
                        iconLeft={null}
                        iconRight={null}
                        onClick={() => {
                          let url = `/learning/${match.params.slug}/edit`;
                          this.props.history.push(url);
                        }}
                      />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <h1 className="text-xl my-10 font-bold">Chapitres</h1>
                  <Table
                    dataSource={chapters}
                    columns={[
                      {
                        field: "title",
                        title: `${chapters.length} chapitres`,
                        renderElement: (record) => (
                          <div class="flex items-center">
                            {record.is_published && (
                              <span class="bg-green-500 h-2 w-2 rounded-full mr-2"></span>
                            )}
                            {!record.is_published && (
                              <span class="bg-gray-500 h-2 w-2 rounded-full mr-2"></span>
                            )}
                            <span class="font-normal">{record.title}</span>
                          </div>
                        ),
                      },
                      {
                        field: "listener_count",
                        title: "Leçons",
                        width: "10%",
                        renderElement: (record) => (
                          <span class="font-normal">
                            {
                              episodes.filter((e) => e.chapter_id === record.id)
                                .length
                            }{" "}
                            leçon(s)
                          </span>
                        ),
                      },
                      {
                        field: "published_at",
                        title: "Date publication",
                        width: "5%",
                        srOnly: true,
                        renderElement: (record) => (
                          <div class="flex items-center space-x-5">
                            <a
                              href="javascript:void(0);"
                              onClick={() => {
                                this.setState({
                                  selectedSeason: record,
                                  seasonEditVisible: true,
                                });
                              }}
                            >
                              <PencilIcon className="w-4 h-4" />
                            </a>
                            <a href="javascript:void(0);" onClick={() => {}}>
                              <TrashIcon className="w-4 h-4 text-red-500" />
                            </a>
                          </div>
                        ),
                      },
                    ]}
                  />

                  <div className="flex py-10 space-x-4">
                    <div>
                      <Button
                        content="Ajouter un chapitre"
                        iconLeft={null}
                        iconRight={null}
                        onClick={() =>
                          this.setState({ seasonCreateVisible: true })
                        }
                      />
                    </div>
                  </div>
                </TabPanel>
              </TabPanels>
            </TabGroup>

            {wizardVisible && (
              <div className="absolute inset-0 bg-white">
                <CreateEpisode
                  pageTitle={"Nouvelle leçon"}
                  aboutText={"Informations sur votre leçon"}
                  podcastId={match.params.slug}
                  chapters={chapters}
                  loading={loadingCreate}
                  onRequestClose={() => this.setState({ wizardVisible: false })}
                />
              </div>
            )}
            {wizardEditVisible && (
              <>
                {selectedEpisode && (
                  <div className="absolute inset-0 bg-white">
                    <EditEpisode
                      podcastId={match.params.slug}
                      episode={selectedEpisode}
                      chapters={chapters}
                      onRequestClose={() =>
                        this.setState({ wizardEditVisible: false })
                      }
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </PageLayout>
        {seasonCreateVisible && (
          <SeasonCreate
            title={"Ajouter un chapitre"}
            podcastId={match.params.slug}
            onRequestClose={() => {
              this.setState({ seasonCreateVisible: false });
            }}
          />
        )}
        {seasonEditVisible && (
          <ChapterEdit
            title={"Modifier un chapitre"}
            chapter={selectedSeason}
            onRequestClose={() => {
              this.setState({ seasonEditVisible: false });
            }}
          />
        )}
      </div>
    );
  }
}

export default PagePodcast;
