import React, { Component } from "react";
import { Card } from "@tremor/react";
import Header from "../../components/header/home";
import PageLayout from "../../components/layout/page";
import ProgressBar from "../../components/progress/indeterminate";
import EmptyView from "../../components/list/empty";
import { BellIcon } from "@heroicons/react/24/outline";

class PageNotifications extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    document.title = "Notifications - Yoka.cd";
    setTimeout(() => this.fetchDataIfNeeded(), 500);
  }

  fetchDataIfNeeded() {}

  render() {
    const { loading } = this.props;

    return (
      <div className="h-full bg-white">
        <Header />
        <PageLayout>
          <div className="bg-white py-10">
            <h1 className="text-4xl font-bold">Vos notifications</h1>
            <hr className="my-5" />
            {loading && <ProgressBar />}
            <div className="flex justify-center">
              <EmptyView
                icon={<BellIcon className="w-24 h-24" />}
                title={"Aucune notification pour l'instant"}
                onPrimaryAction={() => {}}
                primaryActionText="Actualiser la liste"
              />
            </div>
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default PageNotifications;
