/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Select, SelectItem, TextInput } from "@tremor/react";
import { DocumentIcon } from "@heroicons/react/24/outline";
import { addChapter } from "../../actions/chapter";
import Modal from "../../components/modal";
import TextArea from "../../components/form/textarea";

class SeasonCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seasonTitle: "",
      seasonDescription: "",
      seasonPublished: "isPublished",
    };
  }

  onRequestCreate() {
    const { podcastId, seasonType = "chapter" } = this.props;
    const { seasonTitle, seasonDescription, seasonPublished } = this.state;

    if (seasonTitle === "" || seasonDescription === "") return;

    let bodyParams = {
      podcast_id: podcastId,
      type: seasonType,
      title: seasonTitle,
      description: seasonDescription,
      is_published: seasonPublished === "isPublished",
    };

    const { addChapter } = this.props;
    if (addChapter) addChapter(bodyParams);
  }

  onRequestClose() {
    const { onRequestClose } = this.props;
    if (onRequestClose) onRequestClose();
  }

  render() {
    const { loading, title = "Ajouter une saison" } = this.props;

    return (
      <Modal
        title={title}
        buttons={[
          {
            loading,
            content: "Enregistrer",
            type: "primary",
            onClick: () => this.onRequestCreate(),
          },
          {
            content: "Fermer",
            disabled: loading,
            onClick: () => this.onRequestClose(),
          },
        ]}
      >
        <div className="space-y-3 py-5 px-5">
          <div>
            <span className="text-sm text-gray-500">Etat de publication</span>
            <Select
              defaultValue="isPublished"
              placeholder="Sélectionner l'emplacement"
              onValueChange={(seasonPublished) =>
                this.setState({ seasonPublished })
              }
            >
              <SelectItem value="isPublished">Publier directement</SelectItem>
              <SelectItem value="notPublished">Ne pas publier</SelectItem>
            </Select>
          </div>
          <div>
            <span className="text-sm text-gray-500">Titre de la saison</span>
            <TextInput
              icon={DocumentIcon}
              placeholder="Saison 1"
              onChange={(evt) => {
                let seasonTitle = evt.target.value;
                this.setState({ seasonTitle });
              }}
            />
          </div>
          <TextArea
            placeholder="Description"
            onChange={(seasonDescription) => {
              this.setState({ seasonDescription });
            }}
          />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: state.entities.loadingCreate,
});

const mapDispatchToProps = { addChapter };

export default connect(mapStateToProps, mapDispatchToProps)(SeasonCreate);
