import React from "react";
import { Select as SelectList, SelectItem } from "@tremor/react";

const Select = ({
  label,
  options = [],
  onChange = null,
  value,
  defaultValue,
  ...rest
}) => (
  <>
    {label && (
      <label className="block text-sm font-semibold text-gray-700">
        {label}
      </label>
    )}
    <SelectList
      {...rest}
      value={value}
      defaultValue={defaultValue}
      onValueChange={(value) => {
        if (onChange) onChange(value);
      }}
    >
      {options.map((item, x) => (
        <SelectItem
          key={x}
          value={item.value}
          disabled={item.disabled || false}
        >
          {item.label}
        </SelectItem>
      ))}
    </SelectList>
  </>
);

export default Select;
