import React, { Component } from "react";
import QS from "query-string";
import { toast } from "react-toastify";
import Header from "../../components/header/home";
import PageLayout from "../../components/layout/page";
import TextField from "../../components/form/input";
import Button from "../../components/form/button";
import ProgressBar from "../../components/progress/indeterminate";
import Select from "../../components/form/select";
import TextArea from "../../components/form/textarea";
import DatePicker from "../../components/form/datepicker";
import Avatar from "../account/avatar";
import { classNames } from "../../helpers/classNames";

class PageCreate extends Component {
  constructor(props) {
    super(props);

    let parsedQuery = QS.parse(window.location.search);
    const { profile_id } = parsedQuery;

    this.state = {
      createdReleaseId: null,
      profile_id,
      photo_url: null,
      topic: "learning", // [preach,podcast]
      type: "album", // [serial,episodic]
      country_code: "cd",
      title: "",
      description: "",
      copyright: "",
      is_content_explicit: false,
      is_fund_enabled: false,
      primary_genre_id: "none-selected",
      release_date: null,
      facebook_url: "",
      instagram_url: "",
      twitter_url: "",
      youtube_url: "",
      genres_ids: [],
    };
  }

  componentDidMount() {
    document.title = "Créer une sortie - Yoka.cd";

    this.fetchData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { createdReleaseId } = this.props;
    if (createdReleaseId !== prevState.createdReleaseId) {
      if (createdReleaseId)
        this.props.history.push(`/music/${createdReleaseId}`);
    }
  }

  fetchData() {
    const { getCategories } = this.props;
    if (getCategories) getCategories("music", {});
  }

  onCategorySelected(value) {
    let { genres_ids } = this.state;

    let index = genres_ids.findIndex((item) => item === value);
    if (index > 0) {
      genres_ids = genres_ids.filter((_, x) => x !== index);
    } else {
      genres_ids = [...genres_ids, value];
    }

    this.setState({ genres_ids });
  }

  onRequestSubmit() {
    const {
      profile_id,
      type,
      title,
      description,
      copyright,
      photo_url,
      country_code,
      primary_genre_id,
      facebook_url,
      instagram_url,
      twitter_url,
      youtube_url,
      genres_ids,
      is_content_explicit,
      is_fund_enabled,
      release_date,
    } = this.state;

    if (title === "" || description === "") {
      toast("Veuillez compléter tous les champs", {
        type: "warning",
        position: "bottom-center",
      });
      return;
    }

    let bodyParams = {
      topic: "learning",
      profile_id,
      title,
      type,
      description,
      copyright,
      country_code,
      photo_url,
      primary_genre_id,
      facebook_url,
      instagram_url,
      twitter_url,
      youtube_url,
      genres_ids,
      is_content_explicit,
      is_fund_enabled,
      release_date,
      release_date_precision: "date",
    };

    const { createRelease } = this.props;
    if (createRelease) createRelease(bodyParams);
  }

  render() {
    const { genres_ids, primary_genre_id } = this.state;
    const { loading, categories: categories_select } = this.props;

    return (
      <div className="h-full bg-white">
        <Header selectedIndex={-1} />
        <PageLayout>
          <div className="bg-white py-10">
            <h1 className="text-4xl font-bold">Créer une sortie</h1>
            <hr className="my-5" />
            {loading && <ProgressBar />}
            <div className="flex">
              <div className="space-y-4 md:w-1/2">
                <TextField
                  readOnly={loading}
                  nameOrId="release-name"
                  label={"Titre de votre sortie"}
                  placeholder="Choisissez bien le nom de votre sortie"
                  onChange={(title) => this.setState({ title })}
                />
                <TextArea
                  nameOrId="release-description"
                  label={"Description de votre sortie"}
                  placeholder="Brève description de votre sortie"
                  onChange={(description) => this.setState({ description })}
                />
                <TextField
                  readOnly={loading}
                  nameOrId="release-label"
                  label={"Label"}
                  placeholder="ex: Universal Music Africa"
                  onChange={(copyright) => this.setState({ copyright })}
                />

                <Select
                  disabled={loading}
                  nameOrId="podcast-category"
                  label={"Genre principal"}
                  options={[
                    { label: "Sélectionner", value: "none-selected" },
                    ...categories_select,
                  ]}
                  onChange={(primary_genre_id) =>
                    this.setState({
                      primary_genre_id,
                      genres_ids: [primary_genre_id],
                    })
                  }
                />
                {primary_genre_id !== "none-selected" && (
                  <div className="mt-5 space-y-3">
                    <span className="text-sm font-semibold text-gray-700">
                      Ajouter d'autres genres
                    </span>
                    <div className="flex flex-wrap">
                      {categories_select
                        .filter((_, x) => x < 35)
                        .map((item, x) => (
                          <div
                            key={x}
                            onClick={() => this.onCategorySelected(item.value)}
                            className={classNames(
                              "md:mr-2 mb-2 cursor-pointer px-3 py-1 border-[1.5px] rounded-full",
                              genres_ids.includes(item.value)
                                ? " border-primary-400 bg-primary-400 text-white"
                                : " border-gray-400 "
                            )}
                          >
                            <span className="text-sm font-medium">
                              {item.label}
                            </span>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
                <DatePicker
                  readOnly={loading}
                  nameOrId="release-date"
                  label={"Date publication"}
                  placeholder="ex: Universal Music Africa"
                  onChange={(release_date) => this.setState({ release_date })}
                />

                <div className="py-5">
                  <hr className="" />
                </div>
                <TextField
                  readOnly={loading}
                  nameOrId="twitter_url"
                  label={"Lien page Twitter"}
                  placeholder="ex : https://twitter.com/pagexxxx"
                  onChange={(twitter_url) => this.setState({ twitter_url })}
                />
                <TextField
                  readOnly={loading}
                  nameOrId="instagram_url"
                  label={"Lien page instagram"}
                  placeholder="ex : https://instagram.com/pagexxxx"
                  onChange={(instagram_url) => this.setState({ instagram_url })}
                />
                <TextField
                  readOnly={loading}
                  nameOrId="youtube_url"
                  label={"Lien page youtube"}
                  placeholder="ex : https://youtube.com/pagexxxx"
                  onChange={(youtube_url) => this.setState({ youtube_url })}
                />
                <TextField
                  readOnly={loading}
                  nameOrId="facebook_url"
                  label={"Lien page instagram"}
                  placeholder="ex : https://facebook.com/pagexxxx"
                  onChange={(facebook_url) => this.setState({ facebook_url })}
                />
              </div>

              <div className="w-1/2">
                <div className="p-10 md:pt-6">
                  <Avatar
                    buttonText="Pochette"
                    image={this.state.photo_url}
                    imageUploaded={(photo_url) => {
                      this.setState({ photo_url });
                    }}
                  />
                </div>
              </div>
            </div>

            <hr className="my-5" />
            <div className="flex justify-between space-x-3">
              <div>
                <Button
                  disabled={loading}
                  iconLeft={null}
                  iconRight={null}
                  content="Annuler"
                  onClick={() => {
                    this.props.history.push("/music");
                  }}
                />
              </div>
              <div>
                <Button
                  iconLeft={null}
                  iconRight={null}
                  disabled={loading}
                  buttonStyle={"primary"}
                  content="Créer votre sortie"
                  onClick={() => this.onRequestSubmit()}
                />
              </div>
            </div>
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default PageCreate;
