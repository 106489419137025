import { connect } from "react-redux";
import {
  createProfile,
  getProfileById as getProfile,
  editProfile,
} from "../../actions/profile";
import { getProfileById } from "../../selectors/ProfileSelectors";
import component from "../../content/profile/edit";

const mapStateToProps = (state, ownProps) => ({
  loading: state.profile.loading,
  createdProfileId: state.profile.createdProfileId,
  profiles: state.profile.profiles || [],
  profile: getProfileById(state, ownProps.match.params.slug),
});

const mapDispatchToProps = { createProfile, getProfile, editProfile };

export default connect(mapStateToProps, mapDispatchToProps)(component);
