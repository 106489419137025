import React from "react";

export default function TextArea({
  label,
  defaultValue,
  nameOrId = "input_0",
  placeholder = "placeholder",
  onChange,
  ...rest
}) {
  return (
    <div>
      {label && (
        <label
          htmlFor={nameOrId}
          className="block text-sm font-semibold text-gray-700"
        >
          {label}
        </label>
      )}
      <div className="mt-1 relative shadow-sm">
        <textarea
          {...rest}
          id={nameOrId}
          name={nameOrId}
          defaultValue={defaultValue}
          rows={5}
          className="rounded-lg p-4 ring-1 ring-gray-200 focus:outline-gray-200 block w-full"
          placeholder={placeholder}
          onChange={(ev) => {
            let value = ev.target.value;
            if (onChange) onChange(value);
          }}
        />
      </div>
    </div>
  );
}
