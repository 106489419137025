import { toast } from "react-toastify";
import { getCookie } from "../../helpers/cookies";
import {
  releasesList,
  releaseCreate,
  releaseSingle,
  releaseTrackCreate,
  releaseTracks,
  releaseTrackEdit,
} from "../../api/releaseService";

export const setKeyValue = (propKey, propValue) => ({
  type: "ENTITIES_SET_VALUE",
  propKey,
  propValue,
});

export const getReleases =
  (params = {}) =>
  (dispatch, getState) => {
    const state = getState();

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loading", true));
    releasesList(params)
      .then((result) => {
        dispatch(setKeyValue("loading", false));

        let response = result.data;
        const { releases } = response;

        if (releases) {
          dispatch(setKeyValue("releases", releases));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loading", false));
        toast("Echec lors de la récupération des vos sorties !", {
          position: "top-center",
          type: "error",
        });
      });
  };

export const createRelease =
  (bodyParams, params = {}) =>
  (dispatch, getState) => {
    const state = getState();

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingCreate", true));
    releaseCreate(bodyParams, params)
      .then((result) => {
        dispatch(setKeyValue("loadingCreate", false));

        let response = result.data;
        const { release } = response;

        if (release) {
          const { id: createdReleaseId } = release;
          dispatch(setKeyValue("createdReleaseId", createdReleaseId));
          toast("Sortie crée !", {
            position: "top-center",
            type: "success",
          });
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingCreate", false));
        toast("Echec lors de la création du podcast !", {
          position: "top-center",
          type: "error",
        });
      });
  };

export const getRelease =
  (releaseId, params = {}) =>
  (dispatch, getState) => {
    const state = getState();

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingCreate", true));
    releaseSingle(releaseId, params)
      .then((result) => {
        dispatch(setKeyValue("loadingCreate", false));

        let response = result.data;
        const { release } = response;

        if (release) {
          let releasesById = { ...state.entities.releasesById };
          releasesById[release.id] = release;

          let releaseId = `${releasesById.id}-${new Date().getTime()}`;
          dispatch(setKeyValue("releasesById", releasesById));
          dispatch(setKeyValue("releaseId", releaseId));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingCreate", false));
        toast("Echec lors de la récupération de votre sortie !", {
          position: "top-center",
          type: "error",
        });
      });
  };

export const getTracks =
  (releaseId, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { entities } = state;

    params["release_ids"] = releaseId;
    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loading", true));
    releaseTracks(params)
      .then((result) => {
        dispatch(setKeyValue("loading", false));

        let response = result.data;
        const { tracks } = response;

        if (tracks) {
          let tracksByRelease = { ...entities.tracksByRelease };
          tracksByRelease[releaseId] = tracks;

          dispatch(setKeyValue("tracksByRelease", tracksByRelease));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loading", false));
        toast("Echec lors de la récupération des vos pistes !", {
          position: "top-center",
          type: "error",
        });
      });
  };

export const createTrack =
  (bodyParams, params = {}) =>
  (dispatch, getState) => {
    const state = getState();

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingCreate", true));
    releaseTrackCreate(bodyParams, params)
      .then((result) => {
        dispatch(setKeyValue("loadingCreate", false));

        let response = result.data;
        const { track } = response;

        if (track) {
          const { id: createdTrackId } = track;
          dispatch(setKeyValue("createdTrackId", createdTrackId));
          toast("Piste audio crée !", {
            position: "top-center",
            type: "success",
          });

          window.location.reload();
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingCreate", false));
        toast("Echec lors de la création de votre piste !", {
          position: "top-center",
          type: "error",
        });
      });
  };

export const editTrack =
  (trackId, bodyParams, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { live } = state;

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingEdit", true));
    releaseTrackEdit(trackId, bodyParams, params)
      .then((result) => {
        dispatch(setKeyValue("loadingEdit", false));

        let response = result.data;
        const { track } = response;

        if (track) {
          const { id: editedTrackId, release_id } = track;
          dispatch(getTracks(release_id));
          dispatch(setKeyValue("editedTrackId", editedTrackId));
          toast("Piste modifié !", {
            position: "top-center",
            type: "success",
          });
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingEdit", false));
        toast("Echec lors de la modification de votre piste !", {
          position: "top-center",
          type: "error",
        });
      });
  };
