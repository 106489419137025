import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Header from "../../components/header/home";
import PageLayout from "../../components/layout/page";
import ProgressBar from "../../components/progress/indeterminate";
import EmptyView from "../../components/list/empty";
import {
  BellIcon,
  EllipsisHorizontalIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import Table from "../../components/table/table";

class PageProfiles extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    document.title = "Profils - Yoka.cd";
    setTimeout(() => this.fetchDataIfNeeded(), 500);
  }

  fetchDataIfNeeded() {
    const { getProfiles } = this.props;
    if (getProfiles) getProfiles();
  }

  render() {
    const { loading, profiles = [] } = this.props;

    return (
      <div className="h-full bg-white">
        <Header />
        <PageLayout>
          <div className="bg-white py-10">
            <h1 className="text-4xl font-bold">Vos profils</h1>
            <hr className="my-5" />
            {loading && <ProgressBar />}
            <Table
              dataSource={profiles}
              columns={[
                {
                  title: "Titre",
                  key: "title",
                  textAlign: "left",
                  renderElement: (record) => (
                    <Link to={`/profiles/${record.id}`}>
                      <div className="flex items-center space-x-3">
                        <div className="h-16 w-16 bg-gray-100">
                          <img
                            src={record.photo_url}
                            alt="podcast cover"
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <div>
                          <h3>{record.name}</h3>
                          <h3>{record.kind}</h3>
                        </div>
                      </div>
                    </Link>
                  ),
                },
                {
                  title: "Derniere modif.",
                  key: "title",
                  field: "listener_count",
                  textAlign: "right",
                  width: "15%",
                  renderElement: (record) => (
                    <span>
                      {moment(record.created).format("DD MMM, HH:mm")}
                    </span>
                  ),
                },
                {
                  srOnly: true,
                  key: "title",
                  textAlign: "right",
                  width: "5%",
                  renderElement: (record) => (
                    <div>
                      <EllipsisHorizontalIcon className="w-4 h-4" />
                    </div>
                  ),
                },
              ]}
            />
            {profiles.length === 0 && (
              <div className="flex justify-center">
                <EmptyView
                  icon={<UsersIcon className="w-24 h-24" />}
                  title={"Aucune donnée disponible"}
                  primaryActionText="Actualiser la liste"
                  onPrimaryAction={() => this.fetchDataIfNeeded()}
                />
              </div>
            )}
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default PageProfiles;
