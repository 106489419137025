import React, { Component } from "react";

const iconView = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-4 w-4 stroke-icon"
    viewBox="0 0 512 512"
  >
    <title>Filter</title>
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={32}
      d="M32 144h448M112 256h288M208 368h96"
    />
  </svg>
);

class TableColumn extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { title, srOnly, width, textAlign = "left" } = this.props;
    return (
      <>
        {!srOnly && (
          <th
            scope="col"
            style={{ width, textAlign }}
            className="py-5 px-6 text-xs font-semibold tracking-wider text-gray-600 uppercase"
          >
            {title}
          </th>
        )}
        {srOnly && (
          <th
            scope="col"
            className="relative py-3 px-6"
            style={{ width, textAlign }}
          >
            <span className="sr-only">{title}</span>
          </th>
        )}
      </>
    );
  }
}

export default TableColumn;
