import { connect } from "react-redux";
import { getPodcasts } from "../../actions/creators/library";
import component from "../../content/library/index";

const mapStateToProps = (state, ownProps) => ({
  loading: state.profile.loading,
  podcasts: state.metadata.podcasts || [],
  loadingPodcasts: state.profile.loadingPodcasts || false,
});

const mapDispatchToProps = { getPodcasts };

export default connect(mapStateToProps, mapDispatchToProps)(component);
