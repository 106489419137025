import { connect } from "react-redux";
import { loginUser } from "../../actions/session";
import component from "../../content/login/signin";

const mapStateToProps = (state, ownProps) => ({
  loading: state.session.loading || false,
  emailAddress: state.session.emailAddress || false,
});

const mapDispatchToProps = { loginUser };

export default connect(mapStateToProps, mapDispatchToProps)(component);
