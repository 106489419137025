import axios from "axios";
import { slugify, uriFileExtension } from "../utils/StringUtil";
import { completeMultipartUpload, signedUrls } from "../api/assetService";

/* eslint-disable default-case */
class Chunk {
  constructor(props) {
    this.name = props.name;
    this.size = props.size;

    this.sizeUploaded = 0;
    this.totalNumber = 0;
    this.lastAptempt = 0;
    this.retryAptempts = 3;
    this.onProgress = props.onProgress || null;
    this.onFinished = props.onFinished || null;
    this.onUploadPart = props.onUploadPart || null;
    this.onError = props.onError || null;
    this.start = 0;
    this.urlIndex = 0;
    this.file = {};
    this.identity = this.generateRandomString();
    this.codes = [400, 404, 415, 500, 501];
    this.parts = [];
    this.presignedUrls = [];
    this.publicUrl = null;
    this.uploadId = null;
    this.uploadKey = null;
  }

  setFile(file) {
    this.file = file;
    this.setTotalNumber();
  }

  setTotalNumber() {
    const total = Math.ceil(this.file.size / this.size);

    this.totalNumber = total > 0 ? total : 1;
  }

  getNumber() {
    return this.start / this.size + 1;
  }

  generateRandomString(length = 32) {
    return [...Array(length)]
      .map(() => (~~(Math.random() * 36)).toString(36))
      .join("");
  }

  slice(start, end) {
    return this.file.slice(start, end - 1);
  }

  async commit() {
    try {
      let _fileName = slugify(this.file.name);
      let _fileExt = uriFileExtension(this.file.name);

      const { data } = await signedUrls({
        fileName: `${new Date().getTime()}_${_fileName}.${_fileExt}`,
        contentType: this.file.type,
        countParts: this.totalNumber,
      });

      const { presignedUrls, publicUrl, uploadId, uploadKey } = data;
      this.presignedUrls = presignedUrls;
      this.publicUrl = publicUrl;
      this.uploadId = uploadId;
      this.uploadKey = uploadKey;
      console.log({ presignedUrls, publicUrl, uploadId, uploadKey });

      this.handle();
    } catch (error) {
      if (this.onError) this.onError(error);
    }
  }

  handle() {
    this.push(this.start, this.start + this.size + 1);
  }

  push(start, end) {
    let blob = this.slice(start, end);
    if (this.onUploadPart) this.onUploadPart(blob);

    console.log("upload [start]", start);
    console.log("upload [end]", end);
    console.log("upload [size]", this.size);
    console.log("upload [currentNumber]", this.urlIndex);
    console.log("upload [totalNumber]", this.totalNumber);

    let url = this.presignedUrls[this.urlIndex];
    axios
      .put(url, blob)
      .then((res) => {
        this.parts.push({
          ETag: res.headers["etag"],
          PartNumber: this.urlIndex + 1,
        });

        this.urlIndex++;
        this.start += this.size;

        this.sizeUploaded += blob.size;
        let progress = (this.sizeUploaded / this.file.size) * 100;
        if (this.onProgress) this.onProgress(progress);

        // asking for the next chunk...
        if (this.start < this.file.size) {
          this.handle();
        } else {
          this.complete();
        }
      })
      .catch((error) => {
        console.log("Re-uploading the chunk...");
        if (this.lastAptempt < this.retryAptempts) {
          this.lastAptempt++;
          this.handle();
        } else {
          if (this.onError) this.onError(error.response);
        }
      });

    /*axios
      .post(this.url, data, {
        headers: {
          "x-chunk-number": this.getNumber(),
          "x-chunk-total-number": this.totalNumber,
          "x-chunk-size": this.size,
          "x-file-name": this.file.name,
          "x-file-size": this.file.size,
          "x-file-identity": this.identity,
        },
      })
      .then((response) => {
        this.start += this.size;

        switch (response.status) {
          // done
          case 200:
            console.log(response.data);
            if (this.onFinished) this.onFinished(response.data);
            this.lastAptempt = 0;
            break;

          // asking for the next chunk...
          case 201:
            console.log(`${response.data.progress}% uploaded...`);
            if (this.onProgress) this.onProgress(response.data.progress);

            if (this.start < this.file.size) {
              this.commit();
            }
            break;
        }
      })
      .catch((error) => {
        if (error.response) {
          if (this.codes.includes(error.response.status)) {
            console.warn(error.response.status, "Failed to upload the chunk.");
          } else if (error.response.status === 422) {
            console.warn("Validation Error", error.response.data);
          } else {
            console.log("Re-uploading the chunk...");
            if (this.lastAptempt < this.retryAptempts) {
              this.lastAptempt++;
              this.commit();
            } else {
              if (this.onError) this.onError(error);
            }
          }
        } else {
          console.log("Re-uploading the chunk...");
          if (this.lastAptempt < this.retryAptempts) {
            this.lastAptempt++;
            this.commit();
          } else {
            if (this.onError) this.onError(error.response);
          }
        }
      });*/
  }

  complete() {
    completeMultipartUpload({
      uploadId: this.uploadId,
      uploadKey: this.uploadKey,
      parts: this.parts,
    })
      .then((res) => {
        console.log({ res });

        const response = res.data;
        if (this.onFinished) this.onFinished(response);
      })
      .catch((err) => {
        console.log(err);
        if (this.onError) this.onError(err);
      });
  }
}

export default Chunk;
