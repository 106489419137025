/* eslint-disable import/no-anonymous-default-export */
const INITIAL_STATE = {
  agents: [],
  users: [],
  statistics: [],
  loading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "ACCESS_SET_VALUE":
      return { ...state, [action.propKey]: action.propValue };
    default:
      return state;
  }
};
