import React, { Component } from "react";
import PropTypes from "prop-types";
import TableCell from "./cell";

class TableRow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { columns } = this.props;
    return (
      <tr className="bg-white border-b hover:bg-slate-300">
        {columns.map((item, index) => this.renderItem(item, index))}
      </tr>
    );
  }

  renderItem(item, index) {
    const { textAlign } = item;
    const props = this.props;

    const content = this.getContent(index);
    return <TableCell key={index} textAlign={textAlign} content={content} />;
  }

  getContent(rowIndex) {
    const { columns, row } = this.props;
    let element = columns[rowIndex] ? columns[rowIndex] : null;

    console.log(`col index : ${rowIndex}:`, { element });
    console.log(`row index : ${rowIndex}:`, { row });

    if (!element) return null;

    const { field, renderElement: ReactElement } = element;
    let content = row[field];

    if (ReactElement) return <ReactElement {...row} />;
    return content;
  }

  static defaultProps = {
    columns: [],
    displayName: "Hams Walden",
  };

  static propTypes = {
    columns: PropTypes.array.isRequired,
    displayName: PropTypes.string,
  };
}

export default TableRow;
