/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  ChevronDoubleRightIcon,
  MagnifyingGlassIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { getCookie } from "../../helpers/cookies";

const HEADER_LOGO = require("../../../assets/img/logos/logo-header-text.png");
const HEADER_AVATAR = require("../../../assets/img/profiles/photo_empty_image.png");

const menuItems = [];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header({ selectedIndex = -1 }) {
  let account_id = getCookie("account_id");
  let access_token = getCookie("access_token");
  let photo_url = getCookie("photo_url");

  const authenticated = access_token || account_id;
  const userAvatar = photo_url || HEADER_AVATAR;
  return (
    <Popover className="relative bg-black">
      <div className="container mx-auto">
        <div className="flex justify-between items-center md:space-x-10">
          <div className="flex justify-start">
            <Link to="/">
              <img src={HEADER_LOGO} className={"h-14"} alt={"DCGTP"} />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-500">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group
            as="nav"
            className="hidden flex-1 md:flex justify-center"
          >
            {menuItems.map((item, x) => (
              <Link
                key={x}
                to={item.href}
                className={classNames(
                  "uppercase text-sm font-semibold text-white py-8 px-5 border-t-[5px]",
                  selectedIndex === x
                    ? "border-t-primary-500"
                    : "border-transparent hover:border-t-primary-500"
                )}
              >
                <span>{item.text}</span>
              </Link>
            ))}
          </Popover.Group>
          <div className="hidden md:flex items-center justify-end lg:w-0 space-x-3">
            {!authenticated && (
              <>
                <a
                  href="http://www.yoka.cd"
                  target="_blank"
                  rel="noreferrer"
                  className="whitespace-nowrap inline-flex items-center justify-center px-6 py-2 border border-white rounded-full text-sm font-bold text-white space-x-2"
                >
                  <span>Visiter le site</span>
                  <ArrowTopRightOnSquareIcon className="w-5 h-5" />
                </a>
              </>
            )}
            {authenticated && (
              <Link
                to="/home"
                className="whitespace-nowrap inline-flex items-center justify-center space-x-3"
              >
                <img
                  src={userAvatar}
                  alt={"User Avatar"}
                  className="w-8 h-8 rounded-full bg-white"
                />
                <span className="text-sm font-bold text-white">Mon compte</span>
              </Link>
            )}
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute z-50 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <img src={HEADER_LOGO} className={"h-14"} alt={"DCGTP"} />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {menuItems.map((item, index) => (
                    <Link
                      key={index}
                      to={item.href}
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                    >
                      <item.icon
                        className="flex-shrink-0 h-6 w-6 text-primary"
                        aria-hidden="true"
                      />
                      <span className="ml-3 uppercase text-sm font-medium text-gray-900">
                        {item.text}
                      </span>
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
            <div className="w-full py-6 flex items-center space-x-5">
              <div>
                <a
                  href="/#"
                  className="whitespace-nowrap inline-flex items-center justify-center space-x-3 px-6 py-2 text-primary"
                >
                  <MagnifyingGlassIcon className="w-4 h-4" />
                  <span className="text-uppercase text-sm font-bold">
                    Recherche
                  </span>
                </a>
              </div>

              <div>
                <a
                  href="/contact"
                  className="whitespace-nowrap w-full items-center justify-center px-6 py-2 border border-1 border-primary rounded-sm text-sm font-bold text-primary bg-white"
                >
                  Nous écrire
                </a>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
