/* eslint-disable import/no-anonymous-default-export */
const INITIAL_STATE = {
  profileName: "",
  emailAddress: "",
  genderSex: "not_precised",
  birthDate: null,
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "PROFILE_SET_VALUE":
      return { ...state, [action.propKey]: action.propValue };
    case "LOGOUT":
      return INITIAL_STATE;
    default:
      return state;
  }
};
