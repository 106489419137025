import { connect } from "react-redux";
import { editProfile, getProfile } from "../../actions/profile";
import component from "../../content/account/profile";

const mapStateToProps = (state, ownProps) => ({
  loading: state.profile.loading,
  profileId: state.profile.profileId,
  profileName: state.profile.name || "",
  emailAddress: state.profile.email_address || "",
  genderSex: state.profile.gender_sex,
  birthDate: state.profile.birth_date,
  addressCountry: state.profile.addressCountry || "CD",
});

const mapDispatchToProps = { getProfile, editProfile };

export default connect(mapStateToProps, mapDispatchToProps)(component);
