import { connect } from "react-redux";
import component from "../../content/podcast/category";

const mapStateToProps = (state, ownProps) => ({
  loading: state.access.loading,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(component);
