import axios from "axios";
import { API_BASE_HOST } from "../constants/api";
import { removeCookie } from "../helpers/cookies";

const ApiContext = axios.create({
  baseURL: `${API_BASE_HOST}/v1`,
  withCredentials: false,
  timeout: 30000,
  headers: { "Content-Type": "text/plain" },
});

ApiContext.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location.replace("/sign-in?logout=auto");
      removeCookie("access_token");
      removeCookie("account_id");
    }
    return Promise.reject(error.response);
  }
);

/**
 * Session
 */

export function sessionInfo(params = {}) {
  return ApiContext.get(`session`, { params });
}

export function sessionLogin(bodyParams, params = {}) {
  return ApiContext.post(`session/logout`, bodyParams, { params });
}

export function sessionRefresh(bodyParams, params = {}) {
  return ApiContext.post(`session/refresh`, bodyParams, { params });
}

/**
 * Accounts
 */

export function userCreate(body, params = {}) {
  return ApiContext.post(`accounts/signup`, body, { params });
}

export function userLogin(body, params = {}) {
  return ApiContext.post(`accounts/signin`, body, { params });
}

export function accountPassword(body, params = {}) {
  return ApiContext.post(`security/password`, body, { params });
}

/**
 * Profile
 */

export function profileList(params = {}) {
  return ApiContext.get(`profiles`, { params });
}

export function profileCreate(body, params = {}) {
  return ApiContext.post(`profiles`, body, { params });
}

export function profileGet(profileId, params = {}) {
  return ApiContext.get(`profiles/details/${profileId}`, { params });
}

export function profileEdit(profileId, body, params = {}) {
  return ApiContext.post(`profiles/details/${profileId}`, body, { params });
}

export function assetUpload(body, params = {}) {
  return ApiContext.post(`assets/upload`, body, {
    params,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
