import React, { Component } from "react";

class TableCell extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { content, textAlign } = this.props;

    console.log(this.props);
    return (
      <td
        style={{ textAlign }}
        className="py-4 px-6 text-sm font-medium whitespace-nowrap"
      >
        {content}
      </td>
    );
  }
}

export default TableCell;
