/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { Component } from "react";
import { toast } from "react-toastify";
import Plyr from "plyr";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@tremor/react";
import {
  ArrowUpTrayIcon,
  MicrophoneIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import { classNames } from "../../helpers/classNames";

import Button from "../../components/form/button";
import TextField from "../../components/form/input";
import TextArea from "../../components/form/textarea";
import RadioGroup from "../../components/form/radiogroup";
import AudioUploader from "../../components/upload/audio";
import AudioRecorder from "../../components/recorder/audio";
import { UploadCloudIcon } from "lucide-react";

class EpisodeCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileUploading: false,
      textUploading: "Aperçu de votre fichier",
      audioFile: null,
      audioUrl: null,
      audioType: null,
      audioFileName: null,
      audioDuration: 0,
      audioMetadata: null,
      wizardMode: "UPLOAD",
      activeTab: "UPLOAD_AUDIO",

      epTitle: "",
      epDescription: "",
      epSeasonNumber: "",
      epNumber: "",
      epExplicit: false,
      epType: "episode",
      epPublished: false,
    };

    this.player = null;
  }

  componentDidMount() {
    setTimeout(() => this.initPlayer(), 400);
  }

  initPlayer() {
    let playerElement = document.getElementById("player-preview");
    if (playerElement) this.player = new Plyr(playerElement);
  }

  replacePlayerSource() {
    const { audioUrl, audioFileName, audioType } = this.state;
    if (!this.player) return;

    try {
      this.player.autoplay = true;
      this.player.source = {
        type: "audio",
        title: audioFileName,
        sources: [{ src: audioUrl, type: audioType || "audio/mp3" }],
      };
    } catch (error) {}
  }

  onRequestClose() {
    const { onRequestClose } = this.props;
    if (onRequestClose) onRequestClose();
  }

  onRequestSubmit() {
    const {
      epTitle,
      epDescription,
      epExplicit,
      epType,
      epNumber,
      epSeasonNumber,
      epPublished,
      audioFile,
      audioDuration,
      audioUrl,
      audioType,
      audioFileName,
    } = this.state;

    if (epTitle === "" || epDescription === "" || audioFile == null) {
      toast("Veuillez remplir tous les champs réquis", {
        position: "bottom-center",
        type: "error",
      });
      return;
    }

    let bodyRequest = {
      audio: {
        url: audioUrl,
        type: audioType,
        duration: audioDuration,
        filename: audioFileName,
      },
      title: epTitle,
      description: epDescription,
      is_content_explicit: epExplicit,
      type: epType,
      number: epNumber !== "" ? epNumber : null,
      season_number: epSeasonNumber !== "" ? epSeasonNumber : null,
      is_published: epPublished,
    };

    const { createEpisode, podcastId } = this.props;
    if (createEpisode) createEpisode({ ...bodyRequest, podcast_id: podcastId });
  }

  render() {
    const {
      pageTitle = "Nouvel Episode",
      aboutText = "Informations sur l'épisode",
    } = this.props;
    const { activeTab, fileUploading, textUploading, audioUrl, epTitle } =
      this.state;
    return (
      <div>
        <div className="flex space-x-2 justify-between">
          <h1 className="text-4xl font-bold">{pageTitle}</h1>
          <a href="javascript:void(0);" onClick={() => this.onRequestClose()}>
            <XMarkIcon className="w-10 h-10 text-gray-400" />
          </a>
        </div>
        <hr className="my-5" />
        <div className="flex space-x-10">
          <div className="w-6/12">
            <div className="border-[0.5px] px-10 py-5 rounded">
              <h1>{epTitle || textUploading}</h1>
              <hr className="my-5" />
              <audio
                controls={true}
                id={"player-preview"}
                style={{ width: "100%" }}
              >
                {/** <source src={audioUrl} type="audio/mp3" /> */}
              </audio>
            </div>

            <TabGroup>
              <TabList className="mt-8">
                <Tab icon={UploadCloudIcon}>Uploader un audio</Tab>
                <Tab icon={MicrophoneIcon}>Enregistrer un audio</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <div className="py-5">
                    <AudioUploader
                      onGotUrl={(audioUrl) =>
                        this.setState({ audioUrl }, () => {
                          this.replacePlayerSource();
                        })
                      }
                      onGotMetadata={(info) => {
                        this.setState({
                          audioType: info.type,
                          audioFile: info.file,
                          audioFileName: info.name,
                          audioDuration: info.duration,
                        });
                      }}
                    />
                  </div>
                </TabPanel>
                <TabPanel>{/**  <AudioRecorder /> */}</TabPanel>
              </TabPanels>
            </TabGroup>
          </div>
          <div className="w-6/12">
            <h1 className="font-semibold text-2xl">{aboutText}</h1>
            <p>
              Ajoutez des informations et définissez le mode de publication.
            </p>
            <div className="my-5 space-y-3">
              <TextField
                nameOrId="episode-name"
                label={"Titre de l'épisode *"}
                placeholder="Titre"
                onChange={(epTitle) => this.setState({ epTitle })}
              />
              <TextArea
                nameOrId="episode-description"
                label={"Description de l'épisode *"}
                placeholder="Entrez une brève description"
                onChange={(epDescription) => this.setState({ epDescription })}
              />
              <RadioGroup
                label={"Votre épisode comporte-t-il du contenu explicite ?"}
                nameOrId="explicit-content"
                options={[
                  {
                    label: "Oui",
                    value: "include_explicit_content",
                    onChecked: () => this.setState({ epExplicit: true }),
                  },
                  {
                    label: "Non",
                    value: "no_explicit_content",
                    onChecked: () => this.setState({ epExplicit: false }),
                  },
                ]}
              />

              <RadioGroup
                label={"Type d'épisode"}
                nameOrId="episode-type"
                options={[
                  {
                    label: "Complet",
                    value: "episode",
                    onChecked: () => this.setState({ epType: "episode" }),
                  },
                  {
                    label: "Bande-annonce",
                    value: "trailer",
                    onChecked: () => this.setState({ epType: "trailer" }),
                  },
                  {
                    label: "Bonus",
                    value: "bonus",
                    onChecked: () => this.setState({ epType: "bonus" }),
                  },
                ]}
              />

              <RadioGroup
                label={"Disponibilité dans Yoka"}
                nameOrId="publish-state"
                options={[
                  {
                    label: "Publié",
                    value: "is_published",
                    onChecked: () => this.setState({ epPublished: true }),
                  },
                  {
                    label: "Brouillon",
                    value: "is_not_published",
                    onChecked: () => this.setState({ epPublished: false }),
                  },
                ]}
              />

              {/** <div className="flex space-x-5">
                <TextField
                  type="number"
                  nameOrId="episode-season"
                  label="Numéro de la saison"
                  placeholder={"ex : 1"}
                  onChange={(epSeasonNumber) =>
                    this.setState({ epSeasonNumber })
                  }
                />
                <TextField
                  type="number"
                  nameOrId="episode-number"
                  label="Numéro de l'épisode"
                  placeholder={"ex : 5"}
                  onChange={(epNumber) => this.setState({ epNumber })}
                />
              </div> */}
            </div>
          </div>
        </div>

        <hr />
        <div className="flex justify-between pt-2">
          <div>
            <Button
              disabled={fileUploading}
              iconLeft={null}
              iconRight={null}
              content="Quitter"
              onClick={() => this.onRequestClose()}
            />
          </div>
          <div>
            <Button
              buttonStyle={"primary"}
              disabled={fileUploading}
              iconLeft={null}
              iconRight={null}
              content="Enregistrer"
              onClick={() => this.onRequestSubmit()}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default EpisodeCreate;
