import { toast } from "react-toastify";
import { getCookie } from "../../helpers/cookies";
import {
  podcastEpisodeCreate,
  podcastEpisodeEdit,
  podcastEpisodes,
} from "../../api/podcastService";

export const setKeyValue = (propKey, propValue) => ({
  type: "ENTITIES_SET_VALUE",
  propKey,
  propValue,
});

export const getPodcastEpisodes =
  (podcastId, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { entities } = state;

    params["podcast_ids"] = podcastId;
    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loading", true));
    podcastEpisodes(params)
      .then((result) => {
        dispatch(setKeyValue("loading", false));

        let response = result.data;
        const { episodes } = response;

        if (episodes) {
          let episodesByPodcast = { ...entities.episodesByPodcast };
          episodesByPodcast[podcastId] = episodes;

          dispatch(setKeyValue("episodesByPodcast", episodesByPodcast));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loading", false));
        toast("Echec lors de la récupération des vos épisodes !", {
          position: "top-center",
          type: "error",
        });
      });
  };

export const createEpisode =
  (bodyParams, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { live } = state;

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingCreate", true));
    podcastEpisodeCreate(bodyParams, params)
      .then((result) => {
        dispatch(setKeyValue("loadingCreate", false));

        let response = result.data;
        const { episode } = response;

        if (episode) {
          const { id: createEpisodeId } = episode;
          dispatch(setKeyValue("createEpisodeId", createEpisodeId));
          toast("Episode crée !", {
            position: "top-center",
            type: "success",
          });

          window.location.reload();
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingCreate", false));
        toast("Echec lors de la création de votre épisode !", {
          position: "top-center",
          type: "error",
        });
      });
  };

export const editEpisode =
  (episodeId, bodyParams, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { live } = state;

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingEdit", true));
    podcastEpisodeEdit(episodeId, bodyParams, params)
      .then((result) => {
        dispatch(setKeyValue("loadingEdit", false));

        let response = result.data;
        const { episode } = response;

        if (episode) {
          const { id: editedEpisodeId, podcast_id } = episode;
          dispatch(getPodcastEpisodes(podcast_id));
          dispatch(setKeyValue("editedEpisodeId", editedEpisodeId));
          toast("Episode modifié !", {
            position: "top-center",
            type: "success",
          });
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingEdit", false));
        toast("Echec lors de la modification de votre épisode !", {
          position: "top-center",
          type: "error",
        });
      });
  };
