import axios from "axios";
import { API_BASE_HOST } from "../constants/api";
import { removeCookie } from "../helpers/cookies";

const ApiContext = axios.create({
  baseURL: `${API_BASE_HOST}/v1`,
  withCredentials: false,
  timeout: 30000,
  headers: { "Content-Type": "text/plain" },
});

ApiContext.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location.replace("/sign-in?logout=auto");
      removeCookie("access_token");
      removeCookie("account_id");
    }
    return Promise.reject(error.response);
  }
);

/**
 * categories
 */

export function podcastsList(params = {}) {
  return ApiContext.get(`creators/podcasts`, { params });
}

/**
 * categories
 */

export function podcastCreate(body, params = {}) {
  return ApiContext.post(`creators/podcasts`, body, { params });
}

/**
 * episodes
 */

export function podcastEpisodeCreate(body, params = {}) {
  return ApiContext.post(`creators/podcastepisodes`, body, { params });
}

export function podcastEpisodeEdit(refId, body, params = {}) {
  return ApiContext.post(`creators/podcastepisodes/edit/${refId}`, body, {
    params,
  });
}

export function podcastEpisodes(params = {}) {
  return ApiContext.get(`creators/podcastepisodes`, { params });
}

/**
 * Single podcast
 */

export function podcastSingle(refId, params = {}) {
  return ApiContext.get(`creators/podcasts/details/${refId}`, { params });
}

/**
 * Edit podcast
 */

export function podcastEdit(refId, body, params = {}) {
  return ApiContext.post(`creators/podcasts/edit/${refId}`, body, { params });
}

/**
 * Single podcast
 */

export function signedUrl(body, params = {}) {
  return ApiContext.post(`creators/upload/signed_url`, body, { params });
}

export function signedUrlChunk(body, params = {}) {
  return ApiContext.post(`creators/upload/signed_url_chunk`, body, { params });
}
