import { categoriesList } from "../api/metadataService";
import { getCookie } from "../helpers/cookies";

export const setKeyValue = (propKey, propValue) => ({
  type: "METADATA_SET_VALUE",
  propKey,
  propValue,
});

export const getPodcastCategories =
  (params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    let _categories = { ...state.metadata.categories };

    let accessToken = getCookie("access_token");
    params["kind"] = "podcast";
    params["access_token"] = accessToken;

    dispatch(setKeyValue("loading", true));
    categoriesList(params)
      .then((result) => {
        dispatch(setKeyValue("loading", false));

        let response = result.data;
        const { categories } = response;

        if (categories) {
          _categories["podcast"] = categories;
          dispatch(setKeyValue("categories", _categories));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loading", false));
      });
  };

export const getCategories =
  (filterKind = "music", params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    let _categories = { ...state.metadata.categories };

    let accessToken = getCookie("access_token");
    params["kind"] = filterKind;
    params["access_token"] = accessToken;

    dispatch(setKeyValue("loading", true));
    categoriesList(params)
      .then((result) => {
        dispatch(setKeyValue("loading", false));

        let response = result.data;
        const { categories } = response;

        if (categories) {
          _categories[filterKind] = categories;
          dispatch(setKeyValue("categories", _categories));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loading", false));
      });
  };
