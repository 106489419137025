const categories = (state) => state.metadata.categories;
const releasesById = (state) => state.entities.releasesById || {};
const tracksByRelease = (state) => state.entities.tracksByRelease || {};

export const getCategoriesByKind = (state, kind) => {
  let collection = categories(state);

  return collection[kind] || [];
};

export const getReleaseById = (state, refId) => {
  let collection = releasesById(state);

  return collection[refId] || null;
};

export const getTracksByRelease = (state, refId) => {
  let collection = tracksByRelease(state);

  return collection[refId] || [];
};
