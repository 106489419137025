import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import { signedUrl } from "../../api/assetService";
import ProgressBar from "../../components/progress/indeterminate";

const DEFAULT_IMAGE = require("../../../assets/img/profiles/photo_empty_image.png");

export default function Avatar({
  buttonText = "Uploader",
  image = null,
  imageUploaded = null,
}) {
  const [loading, setLoading] = useState(true);

  const onFileChange = async (ev) => {
    let files = ev.target.files;
    let [fileSelected] = files;

    let picker = document.getElementById("file-picker");
    if (picker) picker.value = null;

    try {
      setLoading(true);
      let {
        data: { signedUrl: uploadUrl, objectUrl },
      } = await signedUrl({
        filename: fileSelected.name,
        contentType: fileSelected.type,
        location: "aws",
      });

      const data = await axios.put(uploadUrl, fileSelected, {
        headers: { "Content-Type": fileSelected.type },
      });

      setLoading(false);
      toast("Téléchargement réussi", { type: "success" });

      console.log("headers :", data.headers);
      if (imageUploaded) imageUploaded(objectUrl);
    } catch (error) {
      console.log(error);
      setLoading(false);

      toast("Echec du téléchargement", { type: "error" });
    }
  };

  return (
    <div className="h-[15rem] w-[15rem] relative rounded-lg overflow-hidden">
      <img
        src={image || DEFAULT_IMAGE}
        alt={"Cover"}
        className={"w-full h-full object-cover"}
      />

      <div className="absolute bottom-0 right-0 left-0">
        {loading && <ProgressBar />}
        <button
          className="w-full block bg-primary-200"
          onClick={() => {
            let picker = document.getElementById("file-picker");
            if (picker) picker.click();
          }}
        >
          <div className="flex items-center justify-center px-2 py-3 text-primary-500">
            <span className="text-md font-bold">{buttonText}</span>{" "}
            <ArrowUpTrayIcon className="w-6 h-6 ml-4" />
          </div>
        </button>
        <input
          type="file"
          id="file-picker"
          className="hidden"
          accept="image/pjpeg,image/jpeg,image/png"
          onChange={onFileChange}
        />
      </div>
    </div>
  );
}
