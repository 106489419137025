import { connect } from "react-redux";
import { createEpisode } from "../../actions/creators/podcast";
import component from "../../content/episode/create";

const mapStateToProps = (state, ownProps) => ({
  loadingCreate: state.entities.loadingCreate,
});

const mapDispatchToProps = { createEpisode };

export default connect(mapStateToProps, mapDispatchToProps)(component);
