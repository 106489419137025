/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";

const ProfileTypeChooser = ({ onRequestClose, onSelected }) => {
  return (
    <div className="h-screen bg-white">
      <div className="flex space-x-2 justify-between border-b border-b-gray-200 py-10">
        <h1 className="text-4xl font-bold">Sélectionner le type de profil</h1>
        <a
          href="javascript:void(0);"
          onClick={() => {
            if (onRequestClose) onRequestClose();
          }}
        >
          <XMarkIcon className="w-10 h-10 text-gray-400" />
        </a>
      </div>
      <div className="grid grid-cols-4 gap-3 my-10">
        {[
          {
            kind: "artist",
            title: "Artiste",
            buttonText: "Distribuer ma musique",
            image: require("../../../assets/img/covers/profiles-music-cover.jpeg"),
          },
          {
            kind: "podcaster",
            title: "Podascteur",
            buttonText: "Publier des podcasts",
            image: require("../../../assets/img/covers/profiles-podcast-cover.jpeg"),
          },
          {
            kind: "learner",
            title: "Prédicateur",
            buttonText: "Publier des cours",
            image: require("../../../assets/img/covers/profiles-learn-cover.jpeg"),
          },
          {
            kind: "preacher",
            title: "Prédicateur",
            buttonText: "Publier des prédications",
            image: require("../../../assets/img/covers/profiles-preach-cover.jpeg"),
          },
        ].map((e, x) => (
          <div key={x}>
            <div className="h-[18rem] flex flex-col bg-gray-800 rounded-md overflow-hidden">
              <div className="flex-1 overflow-hidden">
                <img
                  alt=""
                  src={e.image}
                  className="w-full h-full object-cover"
                />
              </div>
              <button
                className="bg-gray-300 py-5 text-center hover:bg-primary-500 hover:text-white"
                onClick={() => {
                  if (onSelected) onSelected(e.kind);
                }}
              >
                <h1 className="font-bold">{e.title}</h1>
                <span className="font-semibold text-sm">{e.buttonText}</span>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProfileTypeChooser;
