import { ArrowRightIcon } from "@heroicons/react/24/outline";
import React, { Component } from "react";
import FooterPage from "../../components/footer";
import Header from "../../components/header/home";

const IMAGE_COVER = require("../../../assets/img/covers/services-cover-00.jpg");

class Error404 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="h-full bg-white">
        <Header selectedIndex={-1} />
        <div className="h-[65vh] relative">
          <img
            src={IMAGE_COVER}
            alt={"Cover"}
            className={"w-full h-full object-cover"}
          />
          <div className="absolute inset-0 flex">
            <div className="w-full h-full flex flex-col justify-center items-center bg-gradient-to-b from-[#071020]">
              <div className="px-10 mx-auto xl:max-w-5xl xl:mx-0 xl:px-40 text-center">
                <h1 className="text-4xl md:text-6xl text-white font-bold leading-tight my-3">
                  Page inexistante
                </h1>
                <div className="space-y-5 my-4 text-white">
                  <p>La page que vous cherchez n'existe pas !</p>
                </div>
                <div className="space-x-5 flex">
                  <a
                    className="md:inline-flex flex items-center justify-center mt-5 px-8 py-4 rounded-full text-shadow-500 bg-white hover:bg-shadow-500 hover:text-white"
                    href={"/"}
                  >
                    <span className="text-md font-bold">Page d'accueil</span>{" "}
                    <ArrowRightIcon className="w-6 h-6 ml-4" />
                  </a>
                  <a
                    className="md:inline-flex flex items-center justify-center mt-5 px-8 py-4 rounded-full text-shadow-500 bg-white hover:bg-shadow-500 hover:text-white"
                    href={"/contact"}
                  >
                    <span className="text-md font-bold">Contactez-nous</span>{" "}
                    <ArrowRightIcon className="w-6 h-6 ml-4" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterPage />
      </div>
    );
  }
}

export default Error404;
