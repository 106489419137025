import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import BaseRouter from "./app/router/base";
import configureStore from "./app/store/configureStore";

const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter basename={"/"}>
        <div className="" role={"main"}>
          <BaseRouter />
        </div>
      </BrowserRouter>
      <ToastContainer />
    </Provider>
  );
}

export default App;
