import React, { Component } from "react";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class PageMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuItems: [
        {
          text: "Mon contenu",
          link: `/library/${props.slug}`,
        },
        {
          text: "Paramètres",
          link: `/settings/profile/${props.slug}`,
        },
      ],
    };
  }

  render() {
    const { menuItems } = this.state;
    const { selectedIndex = 0 } = this.props;
    return (
      <div className="border-b border-gray-200">
        <div className="-mb-px flex px-4 space-x-8 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            viewBox="0 0 512 512"
          >
            <rect
              x="32"
              y="96"
              width="64"
              height="368"
              rx="16"
              ry="16"
              fill="none"
              stroke="currentColor"
              stroke-linejoin="round"
              stroke-width="32"
            />
            <path
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="32"
              d="M112 224h128M112 400h128"
            />
            <rect
              x="112"
              y="160"
              width="128"
              height="304"
              rx="16"
              ry="16"
              fill="none"
              stroke="currentColor"
              stroke-linejoin="round"
              stroke-width="32"
            />
            <rect
              x="256"
              y="48"
              width="96"
              height="416"
              rx="16"
              ry="16"
              fill="none"
              stroke="currentColor"
              stroke-linejoin="round"
              stroke-width="32"
            />
            <path
              d="M422.46 96.11l-40.4 4.25c-11.12 1.17-19.18 11.57-17.93 23.1l34.92 321.59c1.26 11.53 11.37 20 22.49 18.84l40.4-4.25c11.12-1.17 19.18-11.57 17.93-23.1L445 115c-1.31-11.58-11.42-20.06-22.54-18.89z"
              fill="none"
              stroke="currentColor"
              stroke-linejoin="round"
              stroke-width="32"
            />
          </svg>
          {menuItems.map((item, index) => (
            <Link to={item.link}>
              <div
                className={classNames(
                  selectedIndex === index
                    ? "text-indigo-600 border-indigo-600"
                    : "text-gray-900 border-transparent",
                  "flex-1 whitespace-nowrap py-4 px-1 border-b-2 text-base font-medium"
                )}
              >
                {item.text}
              </div>
            </Link>
          ))}
        </div>
      </div>
    );
  }
}

export default PageMenu;
