import { combineReducers } from "redux";
import session from "./session/index";
import balance from "./balance/index";
import metadata from "./metadata/index";
import profile from "./profile/index";
import report from "./report/index";
import access from "./access/index";
import entities from "./entities/index";
import app from "./app/index";

export default combineReducers({
  entities,
  session,
  balance,
  metadata,
  profile,
  report,
  access,
  app,
});
