import { toast } from "react-toastify";
import { releaseEdit, releaseSingle } from "../api/releaseService";
import { getCookie } from "../helpers/cookies";

export const setKeyValue = (propKey, propValue) => ({
  type: "ENTITIES_SET_VALUE",
  propKey,
  propValue,
});

export const getRelease =
  (releaseId, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { metadata } = state;

    let accessToken = getCookie("access_token");
    params["access_token"] = accessToken;

    dispatch(setKeyValue("loading", true));
    releaseSingle(releaseId, params)
      .then((result) => {
        dispatch(setKeyValue("loading", false));

        let response = result.data;
        const { release } = response;

        if (release) {
          let releasesById = { ...state.metadata.releasesById };
          releasesById[release.id] = release;

          let releaseId = `${release.id}-${new Date().getTime()}`;
          dispatch(setKeyValue("releasesById", releasesById));
          dispatch(setKeyValue("releaseId", releaseId));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loading", false));
        toast("Echec lors de la récupération des infos !", {
          position: "top-center",
          type: "error",
        });
      });
  };

export const editRelease =
  (releaseId, bodyParams, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { live } = state;

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingCreate", true));
    releaseEdit(releaseId, bodyParams, params)
      .then((result) => {
        dispatch(setKeyValue("loadingCreate", false));

        let response = result.data;
        const { release } = response;

        if (release) {
          toast("Sortie modifié !", {
            position: "top-center",
            type: "success",
          });
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingCreate", false));
        toast("Echec lors de la modification !", {
          position: "top-center",
          type: "error",
        });
      });
  };
