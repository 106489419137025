import React from "react";
import { Link } from "react-router-dom";
const defaultCover = require("../../../assets/img/profiles/photo_empty_image.png");

const Profile = ({
  name = "Profile Name",
  image = defaultCover,
  linkTo = "/",
}) => {
  return (
    <div className="w-[15rem] h-[18rem] bg-gray-100 rounded-md">
      <Link className="w-full h-full" to={linkTo}>
        <div className="w-full h-full flex flex-col px-10">
          <div className="flex-1 flex flex-col items-center justify-center">
            <div className="w-[8rem] h-[8rem] rounded-full overflow-hidden bg-white">
              <img
                src={image}
                alt={name}
                className="w-full h-full object-cover"
              />
            </div>
          </div>
          <div className="pb-10">
            <h1 className="font-bold text-center">{name}</h1>
          </div>
        </div>
      </Link>
    </div>
  );
};

Profile.propTypes = {};

export default Profile;
