import { connect } from "react-redux";
import { createPodcast } from "../../actions/creators/library";
import { getPodcastCategories as getCategories } from "../../actions/metadata";
import { getCategoriesByKind } from "../../selectors/PodcastSelectors";
import component from "../../content/podcast/create";

const mapStateToProps = (state, ownProps) => ({
  loading: state.metadata.loadingCreate,
  createPodcastId: state.metadata.createPodcastId,
  profiles: state.profile.profiles || [],
  categories: getCategoriesByKind(state, "podcast"),
});

const mapDispatchToProps = { getCategories, createPodcast };

export default connect(mapStateToProps, mapDispatchToProps)(component);
