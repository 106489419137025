import { useEffect } from "react";
import { SelectItem, Select } from "@tremor/react";
import { ShimmerCategoryList } from "react-shimmer-effects";
import { DocumentCheckIcon } from "@heroicons/react/24/outline";
import { useParams, useHistory } from "react-router-dom";
import { usePodcasts } from "../../store/podcasts/hook";
import Header from "../../components/header/home";
import PageLayout from "../../components/layout/page";
import PodcastsTable from "../library/podcast";
import PageMenu from "../library/menu";

const Page = () => {
  const history = useHistory();
  const { slug } = useParams();
  const { loading, podcasts, fetchItems } = usePodcasts(slug);

  const onSelectStateHandler = (state) => {};

  const onButtonCreateHandler = () => {
    let url = `/podcasts/create?profile_id=${slug}`;
    history.push(url);
  };

  useEffect(() => {
    document.title = "Bibliothèque - Yoka pour créateurs";
    return () => {};
  }, []);

  useEffect(() => {
    console.log("useEffect [slug]", slug);
    fetchItems(slug);

    return () => {};
  }, [fetchItems, slug]);

  return (
    <div className="h-full bg-white">
      <Header selectedIndex={-1} />
      <PageLayout>
        <div className="bg-white py-10">
          <h1 className="text-4xl font-bold">Ma bibliothèque</h1>
          <div className="py-10 pt-5">
            <PageMenu slug={slug} />
          </div>

          <div className="flex">
            <div className="space-y-3 md:w-1/3">
              <Select
                icon={DocumentCheckIcon}
                placeholder="Etat de publication"
                onValueChange={(state) => onSelectStateHandler(state)}
              >
                <SelectItem value={"published"}>Publié</SelectItem>
                <SelectItem value={"draft"}>Brouillon</SelectItem>
              </Select>
            </div>
          </div>

          {loading ? (
            <ShimmerCategoryList title items={4} categoryStyle="STYLE_SEVEN" />
          ) : (
            <PodcastsTable
              podcasts={podcasts}
              isEmpty={podcasts.length === 0 && !loading}
              onRequestCreate={onButtonCreateHandler}
              emptyTitle={"Vous n'avez pas encore de podcasts."}
              emptyDescription={"Lancez-vous et créez votre podcast !"}
            />
          )}
        </div>
      </PageLayout>
    </div>
  );
};

export default Page;
