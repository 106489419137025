import { connect } from "react-redux";
import { getProfiles } from "../../actions/profile";
import component from "../../content/account/main";

const mapStateToProps = (state, ownProps) => ({
  loading: state.profile.loading,
  profiles: state.profile.profiles || [],
});

const mapDispatchToProps = { getProfiles };

export default connect(mapStateToProps, mapDispatchToProps)(component);
