import React, { Component } from "react";
import QS from "query-string";
import Header from "../../components/header/home";
import PageLayout from "../../components/layout/page";
import TextField from "../../components/form/input";
import Button from "../../components/form/button";
import ProgressBar from "../../components/progress/indeterminate";
import { toast } from "react-toastify";
import Select from "../../components/form/select";
import TextArea from "../../components/form/textarea";
import Avatar from "../account/avatar";
import PageMenu from "../library/menu";

class ProfileEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile_id: null,
      photo_url: null,
      name: "",
      first_name: "",
      last_name: "",
      about_text: "",
      social_facebook: "",
      social_instagram: "",
      social_twitter: "",
      address_country: "CD",
      profile_type: "corporate",
      profile_kind: "",
    };
  }

  componentDidMount() {
    document.title = "Modifier un profil - Yoka.cd";
    setTimeout(() => this.fetchProfile(), 500);
  }

  componentDidUpdate(prevProps, prevState) {
    const { profile } = this.props;
    if (profile) {
      if (profile.id !== prevState.profile_id) {
        this.setState({
          photo_url: profile.photo_url,
          name: profile.name,
          first_name: profile.first_name,
          last_name: profile.last_name,
          about_text: profile.about_text,
          social_facebook: profile.social_facebook,
          social_instagram: profile.social_instagram,
          social_twitter: profile.social_twitter,
          address_country: profile.address_country,
          profile_type: profile.type,
          profile_kind: profile.kind,
          profile_id: profile.id,
        });
      }
    }
  }

  fetchProfile() {
    const { getProfile, match } = this.props;
    if (getProfile) getProfile(match.params.slug);
  }

  onRequestSubmit() {
    const {
      name,
      first_name,
      last_name,
      about_text,
      photo_url,
      address_country,
      social_facebook,
      social_instagram,
      social_twitter,
      profile_type,
      profile_kind,
    } = this.state;

    if (about_text === "") {
      toast("Les nouveaux mot de passe ne correspondent pas !", {
        type: "warning",
        position: "bottom-center",
      });
      return;
    }

    if (profile_type === "person") {
      if (first_name === "" || last_name === "") {
        toast("Veuillez compléter tous les champs", {
          type: "warning",
          position: "bottom-center",
        });
        return;
      }
    } else if (profile_type === "corporate") {
      if (name === "") {
        toast("Veuillez compléter tous les champs", {
          type: "warning",
          position: "bottom-center",
        });
        return;
      }
    }

    let bodyParams = {
      name,
      first_name,
      last_name,
      type: profile_type,
      kind: profile_kind,
      about_text,
      address_country,
      photo_url,
      social_facebook,
      social_instagram,
      social_twitter,
    };

    const { editProfile, match } = this.props;
    if (editProfile) editProfile(match.params.slug, bodyParams);
  }

  render() {
    const {
      name,
      first_name,
      last_name,
      about_text,
      photo_url,
      address_country,
      social_facebook,
      social_instagram,
      social_twitter,
      profile_kind,
      profile_type,
    } = this.state;
    const { loading, profile, match } = this.props;

    return (
      <div className="h-full bg-white">
        <Header selectedIndex={-1} />
        <PageLayout>
          <div className="bg-white py-10">
            <h1 className="text-4xl font-bold">Modifier le profil</h1>
            <div className="py-10 pt-5">
              <PageMenu slug={match.params.slug} selectedIndex={1} />
            </div>

            {loading && <ProgressBar />}
            <div className="flex space-x-10">
              <div className="">
                <Avatar
                  buttonText="Choisir une photo"
                  image={this.state.photo_url}
                  imageUploaded={(photo_url) => {
                    this.setState({ photo_url });
                  }}
                />
              </div>
              <div className="space-y-3 md:w-[50%]">
                {profile_type === "corporate" && (
                  <TextField
                    readOnly={loading}
                    nameOrId="profile-name"
                    placeholder="ex : John Wick"
                    label={"Nom de votre profil"}
                    value={name}
                    onChange={(name) => this.setState({ name })}
                  />
                )}
                {profile_type === "person" && (
                  <div className="flex space-x-5">
                    <div className="flex-1">
                      <TextField
                        readOnly={loading}
                        nameOrId="first-name"
                        label={"Prenom"}
                        placeholder="ex : John"
                        value={first_name}
                        onChange={(first_name) => this.setState({ first_name })}
                      />
                    </div>
                    <div className="flex-1">
                      <TextField
                        readOnly={loading}
                        nameOrId="last-name"
                        label={"Nom de famille"}
                        placeholder="ex : Wick"
                        value={last_name}
                        onChange={(last_name) => this.setState({ last_name })}
                      />
                    </div>
                  </div>
                )}
                <TextArea
                  value={about_text}
                  label={"A propos de votre profil"}
                  placeholder="Entrez une brève description de votre profil"
                  onChange={(about_text) => this.setState({ about_text })}
                />
                <div className="py-5">
                  <hr className="" />
                </div>
                <Select
                  value={profile_type}
                  nameOrId="profile-type"
                  label={"Type du profil"}
                  onChange={(profile_type) => this.setState({ profile_type })}
                  disabled={true}
                  options={[
                    { label: "Particulier", value: "person" },
                    { label: "Business", value: "corporate" },
                  ]}
                />
                <Select
                  disabled={loading}
                  value={address_country}
                  nameOrId="address-country"
                  label={"Pays ou Région"}
                  options={[{ label: "Congo - Kinshasa", value: "CD" }]}
                  onChange={(address_country) =>
                    this.setState({ address_country })
                  }
                />
                <TextField
                  readOnly={loading}
                  value={social_facebook}
                  nameOrId="social-facebook"
                  label={"Lien page facebook"}
                  placeholder="ex : https://fb.me/pagexxxx"
                  onChange={(social_facebook) =>
                    this.setState({ social_facebook })
                  }
                />
                <TextField
                  readOnly={loading}
                  value={social_twitter}
                  nameOrId="social-twitter"
                  label={"Lien page facebook"}
                  placeholder="ex : https://twitter.com/pagexxxx"
                  onChange={(social_twitter) =>
                    this.setState({ social_twitter })
                  }
                />
                <TextField
                  readOnly={loading}
                  value={social_instagram}
                  nameOrId="social-twitter"
                  label={"Lien page instagram"}
                  placeholder="ex : https://instagram.com/pagexxxx"
                  onChange={(social_instagram) =>
                    this.setState({ social_instagram })
                  }
                />
              </div>
            </div>

            <hr className="my-5" />
            <div className="flex justify-between space-x-3">
              <div>
                <Button
                  disabled={loading}
                  iconLeft={null}
                  iconRight={null}
                  content="Annuler"
                  onClick={() => {
                    this.props.history.push(`/${match.params.slug}`);
                  }}
                />
              </div>
              <div>
                <Button
                  iconLeft={null}
                  iconRight={null}
                  disabled={loading}
                  content="Modifier le profil"
                  buttonStyle={"primary"}
                  onClick={() => this.onRequestSubmit()}
                />
              </div>
            </div>
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default ProfileEdit;
