import { connect } from "react-redux";
import { createUser } from "../../actions/session";
import component from "../../content/login/signup";

const mapStateToProps = (state, ownProps) => ({
  loading: state.session.loading || false,
  emailAddress: state.session.emailAddress || false,
});

const mapDispatchToProps = { createUser };

export default connect(mapStateToProps, mapDispatchToProps)(component);
