import React from "react";
import { Link } from "react-router-dom";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import Table from "../../components/table/table";
import EmptyView from "../../components/list/empty";
import moment from "moment";

const PodcastsTable = ({
  linkTopic = "podcasts",
  podcasts = [],
  isEmpty = false,
  onRequestCreate,
  emptyTitle,
  emptyDescription,
}) => {
  return (
    <div>
      <Table
        dataSource={podcasts}
        columns={[
          {
            key: "title",
            title: "Titre",
            textAlign: "left",
            renderElement: (record) => (
              <Link to={`/${linkTopic}/${record.id}`}>
                <div className="flex items-center space-x-3">
                  <div className="h-16 w-16 bg-gray-100">
                    <img
                      src={record.photo_url}
                      alt="podcast cover"
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="space-y-2">
                    <h3 className="font-bold">{record.title}</h3>
                    <h5 className="text-gray-500 text-sm">
                      Crée le {moment(record.created).format("DD/MM/YYYY")}
                    </h5>
                  </div>
                </div>
              </Link>
            ),
          },
          {
            title: "Ecoutes",
            key: "stream_count",
            field: "stream_count",
            textAlign: "center",
            width: "10%",
          },
          {
            title: "Auditeurs",
            key: "listener_count",
            field: "listener_count",
            textAlign: "center",
            width: "10%",
          },
          {
            title: "Date dernier episode",
            key: "title",
            field: "listener_count",
            textAlign: "right",
            width: "15%",
            renderElement: (record) => (
              <span>
                {moment(record.last_episode_date).format("DD MMM, HH:mm")}
              </span>
            ),
          },
          {
            srOnly: true,
            key: "title",
            textAlign: "right",
            width: "5%",
            renderElement: (record) => (
              <div>
                <EllipsisHorizontalIcon className="w-4 h-4" />
              </div>
            ),
          },
        ]}
      />
      {isEmpty && (
        <EmptyView
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="flex-shrink-0 w-28 h-28"
              viewBox="0 0 512 512"
            >
              <path
                d="M83 384c-13-33-35-93.37-35-128C48 141.12 149.33 48 256 48s208 93.12 208 208c0 34.63-23 97-35 128"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="32"
              />
              <path
                d="M108.39 270.13l-13.69 8h0c-30.23 17.7-31.7 72.41-3.38 122.2s75.87 75.81 106.1 58.12h0l13.69-8a16.16 16.16 0 005.78-21.87L130 276a15.74 15.74 0 00-21.61-5.87zM403.61 270.13l13.69 8h0c30.23 17.69 31.74 72.4 3.38 122.19s-75.87 75.81-106.1 58.12h0l-13.69-8a16.16 16.16 0 01-5.78-21.87L382 276a15.74 15.74 0 0121.61-5.87z"
                fill="none"
                stroke="currentColor"
                stroke-miterlimit="10"
                stroke-width="32"
              />
            </svg>
          }
          title={emptyTitle}
          description={emptyDescription}
          onPrimaryAction={() => {
            if (onRequestCreate) onRequestCreate();
          }}
          onSecondaryAction={() => {}}
        />
      )}
    </div>
  );
};

PodcastsTable.propTypes = {};

export default PodcastsTable;
