import React, { Component } from "react";
import {
  AreaChart,
  Card,
  Metric,
  TabList,
  Tab,
  TabGroup,
  TabPanels,
  TabPanel,
  SelectItem,
  Select,
} from "@tremor/react";
import { Squares2X2Icon } from "@heroicons/react/24/outline";
import Header from "../../components/header/home";
import PageLayout from "../../components/layout/page";
import {
  numberFormatter,
  percentageFormatter,
  sumArray,
} from "../../utils/NumberUtil";

class PageAccount extends Component {
  componentDidMount() {
    document.title = "Compte - Yoka.cd";
    setTimeout(() => this.fetchProfiles(), 500);
  }

  fetchProfiles() {
    const { getProfiles } = this.props;
    if (getProfiles) getProfiles({ kind: "podcaster,artist,preacher,learner" });
  }

  onProfileSelectHandler(id) {}

  render() {
    const { profiles = [] } = this.props;

    const data = [
      {
        Month: "Jan 22",
        Listeners: 0,
        Streams: 0,
        Followers: 0,
      },
      {
        Month: "Jan 22",
        Listeners: 0,
        Streams: 0,
        Followers: 0,
      },
      {
        Month: "Jan 23",
        Listeners: 0,
        Streams: 0,
        Followers: 0,
      },
    ];

    return (
      <div className="h-full bg-white">
        <Header selectedIndex={-1} />
        <PageLayout>
          <div className="bg-white py-10">
            <h1 className="text-4xl font-bold">Vue d'ensemble du compte</h1>
            <hr className="my-5" />
            <div className="flex">
              <div className="space-y-3 md:w-4/12">
                <Select
                  icon={Squares2X2Icon}
                  placeholder="Sélectionner un profil"
                  onValueChange={(pid) => this.onProfileSelectHandler(pid)}
                >
                  {profiles.map((item) => (
                    <SelectItem value={item.id}>{item.name}</SelectItem>
                  ))}
                </Select>
              </div>
            </div>

            <hr className="my-5" />

            <Card className="p-0">
              <TabGroup>
                <TabList>
                  <Tab className="p-4 sm:p-6 text-left">
                    <p className="text-sm sm:text-base">Auditeurs</p>
                    <Metric className="mt-2 text-inherit">
                      {numberFormatter(sumArray(data, "Listeners"))}
                    </Metric>
                  </Tab>
                  <Tab className="p-4 sm:p-6 text-left">
                    <p className="text-sm sm:text-base">Écoutes</p>
                    <Metric className="mt-2 text-inherit">
                      {numberFormatter(sumArray(data, "Streams"))}
                    </Metric>
                  </Tab>
                  <Tab className="p-4 sm:p-6 text-left">
                    <p className="text-sm sm:text-base">Abonnés</p>
                    <Metric className="mt-2 text-inherit">
                      {numberFormatter(
                        sumArray(data, "Followers") / data.length
                      )}
                    </Metric>
                  </Tab>
                </TabList>
                <TabPanels>
                  <TabPanel className="p-6">
                    <AreaChart
                      className="h-80 mt-10"
                      data={data}
                      index="Month"
                      categories={["Listeners"]}
                      colors={["blue"]}
                      valueFormatter={numberFormatter}
                      showLegend={false}
                      yAxisWidth={50}
                    />
                  </TabPanel>
                  <TabPanel className="p-6">
                    <AreaChart
                      className="h-80 mt-10"
                      data={data}
                      index="Month"
                      categories={["Streams"]}
                      colors={["blue"]}
                      valueFormatter={numberFormatter}
                      showLegend={false}
                      yAxisWidth={50}
                    />
                  </TabPanel>
                  <TabPanel className="p-6">
                    <AreaChart
                      className="h-80 mt-10"
                      data={data}
                      index="Month"
                      categories={["Followers"]}
                      colors={["blue"]}
                      valueFormatter={percentageFormatter}
                      showLegend={false}
                      yAxisWidth={40}
                    />
                  </TabPanel>
                </TabPanels>
              </TabGroup>
            </Card>
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default PageAccount;
