import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

const AnalyticData = ({ title, sub_title, help_text }) => {
  return (
    <div>
      <h3 className="text-4xl font-bold">{title}</h3>
      <div className="flex space-x-2">
        <h3 className="text-sm mb-0 text-gray-500">{sub_title}</h3>
        <QuestionMarkCircleIcon className="w-3 h-3 text-gray-500" />
      </div>
    </div>
  );
};

export default AnalyticData;
