import React from "react";
import Logo from "../../../assets/img/profiles/photo_empty_image.png";
import { Link } from "react-router-dom";
import useProfiles from "../../hooks/useProfiles";

const _menu = {
  primary: [
    {
      link: "/home",
      text: "Vue d'ensemble",
      title: "Vue d'ensemble",
      indicator: null,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="flex-shrink-0 w-6 h-6 text-gray-400 fill-white transition duration-75 group-hover:text-white"
          viewBox="0 0 512 512"
        >
          <path
            d="M80 212v236a16 16 0 0016 16h96V328a24 24 0 0124-24h80a24 24 0 0124 24v136h96a16 16 0 0016-16V212"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="32"
          />
          <path
            d="M480 256L266.89 52c-5-5.28-16.69-5.34-21.78 0L32 256M400 179V64h-48v69"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="32"
          />
        </svg>
      ),
    },
    {
      link: "/revenue",
      text: "Monetisation",
      title: "Monetisation",
      indicator: null,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="flex-shrink-0 w-6 h-6 text-gray-400 fill-white transition duration-75 group-hover:text-white"
          viewBox="0 0 512 512"
        >
          <path
            d="M416 221.25V416a48 48 0 01-48 48H144a48 48 0 01-48-48V96a48 48 0 0148-48h98.75a32 32 0 0122.62 9.37l141.26 141.26a32 32 0 019.37 22.62z"
            fill="none"
            stroke="currentColor"
            stroke-linejoin="round"
            stroke-width="32"
          />
          <path
            d="M256 56v120a32 32 0 0032 32h120M176 288h160M176 368h160"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="32"
          />
        </svg>
      ),
    },
    {
      link: "/notifications",
      text: "Notifications",
      title: "Notifications",
      indicator: null,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="flex-shrink-0 w-6 h-6 text-gray-400 fill-white transition duration-75 group-hover:text-white"
          viewBox="0 0 512 512"
        >
          <path
            d="M427.68 351.43C402 320 383.87 304 383.87 217.35 383.87 138 343.35 109.73 310 96c-4.43-1.82-8.6-6-9.95-10.55C294.2 65.54 277.8 48 256 48s-38.21 17.55-44 37.47c-1.35 4.6-5.52 8.71-9.95 10.53-33.39 13.75-73.87 41.92-73.87 121.35C128.13 304 110 320 84.32 351.43 73.68 364.45 83 384 101.61 384h308.88c18.51 0 27.77-19.61 17.19-32.57zM320 384v16a64 64 0 01-128 0v-16"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="32"
          />
        </svg>
      ),
    },
  ],
  footer: [
    {
      link: "/profiles/create",
      text: "Créer un profil",
      title: "Créer un profil",
      indicator: null,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
          viewBox="0 0 512 512"
        >
          <path
            d="M376 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="32"
          />
          <path
            d="M288 304c-87 0-175.3 48-191.64 138.6-2 10.92 4.21 21.4 15.65 21.4H464c11.44 0 17.62-10.48 15.65-21.4C463.3 352 375 304 288 304z"
            fill="none"
            stroke="currentColor"
            stroke-miterlimit="10"
            stroke-width="32"
          />
          <path
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="32"
            d="M88 176v112M144 232H32"
          />
        </svg>
      ),
    },
    /**
    {
      link: "/account",
      text: "Compte",
      title: "Compte",
      indicator: null,
      icon: (
        <svg
          className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      ),
    }, {
      link: "/logout",
      text: "Deconnexion",
      title: "Deconnexion",
      indicator: null,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="flex-shrink-0 w-6 h-6 text-gray-400 fill-white transition duration-75 group-hover:text-white"
          viewBox="0 0 512 512"
        >
          <path
            d="M304 336v40a40 40 0 01-40 40H104a40 40 0 01-40-40V136a40 40 0 0140-40h152c22.09 0 48 17.91 48 40v40M368 336l80-80-80-80M176 256h256"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="32"
          />
        </svg>
      ),
    }, */
  ],
};

const PageLayout = ({ children, menu = _menu }) => {
  const { profiles } = useProfiles();
  return (
    <div>
      <button
        data-drawer-target="logo-sidebar"
        data-drawer-toggle="logo-sidebar"
        aria-controls="logo-sidebar"
        type="button"
        className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          />
        </svg>
      </button>
      <aside
        id="logo-sidebar"
        className="absolute w-[15rem] h-screen transition-transform -translate-x-full sm:translate-x-0 font-patron"
        aria-label="Sidebar"
      >
        <div className="h-full overflow-y-auto bg-[#fafafa]">
          <div className=" px-3 py-10">
            <a href="/home" className="flex items-center justify-center">
              <img
                src={Logo}
                className="h-20 w-20 rounded-full object-cover"
                alt="Logo header"
              />
            </a>
          </div>
          <ul className="space-y-2 font-medium px-3 py-4">
            {menu.primary.map((item, x) => (
              <li>
                <Link
                  key={x}
                  to={item.link}
                  title={item.title}
                  className="flex items-center p-2 text-black rounded-lg hover:text-primary-500"
                >
                  {item.icon}
                  <span className="flex-1 ml-3 whitespace-nowrap font-semibold">
                    {item.text}
                  </span>
                  {item.indicator && (
                    <span className="inline-flex items-center justify-center w-3 h-3 p-3 ml-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">
                      {item.indicator}
                    </span>
                  )}
                </Link>
              </li>
            ))}
          </ul>

          {menu.footer.length > 0 && (
            <div className="border-t border-[#5a5e73] mt-4">
              <ul className="space-y-2 font-medium px-3 py-4">
                {menu.footer.map((item, x) => (
                  <li>
                    <Link
                      key={x}
                      to={item.link}
                      title={item.title}
                      className="flex items-center p-2 text-black rounded-lg hover:text-primary-500"
                    >
                      {item.icon}
                      <span className="flex-1 ml-3 whitespace-nowrap font-semibold">
                        {item.text}
                      </span>
                      {item.indicator && (
                        <span className="inline-flex items-center justify-center w-3 h-3 p-3 ml-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">
                          {item.indicator}
                        </span>
                      )}
                    </Link>
                  </li>
                ))}
                {profiles.map((item, x) => (
                  <li>
                    <Link
                      key={x}
                      to={`/${item.id}`}
                      title={item.title}
                      className="flex items-center p-2 text-black rounded-lg hover:text-primary-500"
                    >
                      <div className="w-7 h-7 bg-gray-500 rounded-full overflow-hidden">
                        <img
                          src={item.photo_url}
                          alt="podcast cover"
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <span className="flex-1 ml-3 whitespace-nowrap font-semibold">
                        {item.name}
                      </span>
                      {item.indicator && (
                        <span className="inline-flex items-center justify-center w-3 h-3 p-3 ml-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">
                          {item.indicator}
                        </span>
                      )}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </aside>
      <div className="p-4 sm:ml-64 xl:px-20">
        <div className="p-4">{children}</div>
      </div>
    </div>
  );
};

PageLayout.propTypes = {};

export default PageLayout;
