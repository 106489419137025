import { toast } from "react-toastify";
import { getCookie } from "../../helpers/cookies";
import {
  podcastCreate,
  podcastEdit,
  podcastSingle,
  podcastsList,
} from "../../api/podcastService";

export const setKeyValue = (propKey, propValue) => ({
  type: "METADATA_SET_VALUE",
  propKey,
  propValue,
});

export const getPodcasts =
  (params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { live } = state;

    let accessToken = getCookie("access_token");
    params["access_token"] = accessToken;

    dispatch(setKeyValue("loadingPodcasts", true));
    podcastsList(params)
      .then((result) => {
        dispatch(setKeyValue("loadingPodcasts", false));

        let response = result.data;
        const { podcasts } = response;

        if (podcasts) {
          let podcastListId = `list__${new Date().getTime()}`;
          dispatch(setKeyValue("podcasts", podcasts));
          dispatch(setKeyValue("podcastListId", podcastListId));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingPodcasts", false));
      });
  };

export const getPodcast =
  (podcastId, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { metadata } = state;

    let accessToken = getCookie("access_token");
    params["access_token"] = accessToken;

    dispatch(setKeyValue("loading", true));
    podcastSingle(podcastId, params)
      .then((result) => {
        dispatch(setKeyValue("loading", false));

        let response = result.data;
        const { podcast } = response;

        if (podcast) {
          let podcastsById = { ...state.metadata.podcastsById };
          podcastsById[podcast.id] = podcast;

          let podcastId = `${podcast.id}-${new Date().getTime()}`;
          dispatch(setKeyValue("podcastsById", podcastsById));
          dispatch(setKeyValue("podcastId", podcastId));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loading", false));
        toast("Echec lors de la récupération du podcast!", {
          position: "top-center",
          type: "error",
        });
      });
  };

export const createPodcast =
  (bodyParams, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { live } = state;

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingCreate", true));
    podcastCreate(bodyParams, params)
      .then((result) => {
        dispatch(setKeyValue("loadingCreate", false));

        let response = result.data;
        const { podcast } = response;

        if (podcast) {
          const { id: createPodcastId } = podcast;
          dispatch(setKeyValue("createPodcastId", createPodcastId));
          toast("Podcast crée !", {
            position: "top-center",
            type: "success",
          });
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingCreate", false));
        toast("Echec lors de la création du podcast !", {
          position: "top-center",
          type: "error",
        });
      });
  };

export const editPodcast =
  (podcastId, bodyParams, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { live } = state;

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingCreate", true));
    podcastEdit(podcastId, bodyParams, params)
      .then((result) => {
        dispatch(setKeyValue("loadingCreate", false));

        let response = result.data;
        const { podcast } = response;

        if (podcast) {
          toast("Podcast modifié !", {
            position: "top-center",
            type: "success",
          });
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingCreate", false));
        toast("Echec lors de la modification du podcast !", {
          position: "top-center",
          type: "error",
        });
      });
  };
