export const CATEGORIES = {
  Arts: [
    "Books",
    "Design",
    "Fashion & Beauty",
    "Food",
    "Performing Arts",
    "Visual Arts",
  ],
  Business: [
    "Careers",
    "Entrepreneurship",
    "Investing",
    "Management",
    "Marketing",
    "Non-Profit",
  ],
  Comedy: ["Comedy Interviews", "Improv", "Stand-Up"],
  Education: ["Courses", "How To", "Language Learning", "Self-Improvement"],
  Fiction: ["Comedy Fiction", "Drama", "Science Fiction"],
  Government: [],
  History: [],
  "Health & Fitness": [
    "Alternative Health",
    "Fitness",
    "Medicine",
    "Mental Health",
    "Nutrition",
    "Sexuality",
  ],
  "Kids & Family": [
    "Education for Kids",
    "Parenting",
    "Pets & Animals",
    "Stories for Kids",
  ],
  Leisure: [
    "Animation & Manga",
    "Automotive",
    "Aviation",
    "Crafts",
    "Games",
    "Hobbies",
    "Home & Garden",
    "Video Games",
  ],
  Music: ["Music Commentary", "Music History", "Music Interviews"],
  News: [
    "Business News",
    "Daily News",
    "Entertainment News",
    "News Commentary",
    "Politics",
    "Sports News",
    "Tech News",
  ],
  "Religion & Spirituality": [
    "Buddhism",
    "Christianity",
    "Hinduism",
    "Islam",
    "Judaism",
    "Religion",
    "Spirituality",
  ],
  Science: [
    "Astronomy",
    "Chemistry",
    "Earth Sciences",
    "Life Sciences",
    "Mathematics",
    "Natural Sciences",
    "Nature",
    "Physics",
    "Social Sciences",
  ],
  "Society & Culture": [
    "Documentary",
    "Personal Journals",
    "Philosophy",
    "Places & Travel",
    "Relationships",
  ],
  Sports: [
    "Baseball",
    "Basketball",
    "Cricket",
    "Fantasy Sports",
    "Football",
    "Golf",
    "Hockey",
    "Rugby",
    "Running",
    "Soccer",
    "Swimming",
    "Tennis",
    "Volleyball",
    "Wilderness",
    "Wrestling",
  ],
  Technology: [],
  "True Crime": [],
  "TV & Film": [
    "After Shows",
    "Film History",
    "Film Interviews",
    "Film Reviews",
    "TV Reviews",
  ],
};

export const CATEGORY_OPTIONS = [
  {
    value: "Arts",
    display: "Arts",
    subCategories: [
      {
        value: "Arts|Books",
        display: "Livres",
      },
      {
        value: "Arts|Design",
        display: "Design",
      },
      {
        value: "Arts|Fashion & Beauty",
        display: "Mode et beauté",
      },
      {
        value: "Arts|Food",
        display: "Alimentation",
      },
      {
        value: "Arts|Performing Arts",
        display: "Arts du spectacle",
      },
      {
        value: "Arts|Visual Arts",
        display: "Arts visuels",
      },
    ],
  },
  {
    value: "Business",
    display: "Affaires",
    subCategories: [
      {
        value: "Business|Careers",
        display: "Carrière",
      },
      {
        value: "Business|Entrepreneurship",
        display: "Entreprenariat",
      },
      {
        value: "Business|Investing",
        display: "Investissement",
      },
      {
        value: "Business|Management",
        display: "Gestion",
      },
      {
        value: "Business|Marketing",
        display: "Marketing",
      },
      {
        value: "Business|Non-Profit",
        display: "À but non lucratif",
      },
    ],
  },
  {
    value: "Comedy",
    display: "Humour",
    subCategories: [
      {
        value: "Comedy|Comedy Interviews",
        display: "Comédie : les interviews",
      },
      {
        value: "Comedy|Improv",
        display: "Improvisation",
      },
      {
        value: "Comedy|Stand-Up",
        display: "One-man shows",
      },
    ],
  },
  {
    value: "Education",
    display: "Éducation",
    subCategories: [
      {
        value: "Education|Courses",
        display: "Cours",
      },
      {
        value: "Education|How To",
        display: "Tuto",
      },
      {
        value: "Education|Language Learning",
        display: "Apprentissage des langues",
      },
      {
        value: "Education|Self-Improvement",
        display: "Développement personnel",
      },
    ],
  },
  {
    value: "Fiction",
    display: "Romans et nouvelles",
    subCategories: [
      {
        value: "Fiction|Comedy Fiction",
        display: "Comédies",
      },
      {
        value: "Fiction|Drama",
        display: "Drame",
      },
      {
        value: "Fiction|Science Fiction",
        display: "Science-fiction",
      },
    ],
  },
  {
    value: "Government",
    display: "Gouvernement",
  },
  {
    value: "History",
    display: "Histoire",
  },
  {
    value: "Health & Fitness",
    display: "Forme et santé",
    subCategories: [
      {
        value: "Health & Fitness|Alternative Health",
        display: "Médecine parallèle",
      },
      {
        value: "Health & Fitness|Fitness",
        display: "Fitness",
      },
      {
        value: "Health & Fitness|Medicine",
        display: "Médecine",
      },
      {
        value: "Health & Fitness|Mental Health",
        display: "Santé mentale",
      },
      {
        value: "Health & Fitness|Nutrition",
        display: "Alimentation",
      },
      {
        value: "Health & Fitness|Sexuality",
        display: "Sexualité",
      },
    ],
  },
  {
    value: "Kids & Family",
    display: "Famille",
    subCategories: [
      {
        value: "Kids & Family|Education for Kids",
        display: "Enfants et éducation",
      },
      {
        value: "Kids & Family|Parenting",
        display: "Famille – parents",
      },
      {
        value: "Kids & Family|Pets & Animals",
        display: "Animaux",
      },
      {
        value: "Kids & Family|Stories for Kids",
        display: "Histoires pour enfants",
      },
    ],
  },
  {
    value: "Leisure",
    display: "Loisirs",
    subCategories: [
      {
        value: "Leisure|Animation & Manga",
        display: "Animation et manga",
      },
      {
        value: "Leisure|Automotive",
        display: "Automobile",
      },
      {
        value: "Leisure|Aviation",
        display: "Aviation",
      },
      {
        value: "Leisure|Crafts",
        display: "Travaux manuels",
      },
      {
        value: "Leisure|Games",
        display: "Jeux",
      },
      {
        value: "Leisure|Hobbies",
        display: "Hobbies",
      },
      {
        value: "Leisure|Home & Garden",
        display: "Maison et jardin",
      },
      {
        value: "Leisure|Video Games",
        display: "Jeux vidéo",
      },
    ],
  },
  {
    value: "Music",
    display: "Musique",
    subCategories: [
      {
        value: "Music|Music Commentary",
        display: "Musique : analyses",
      },
      {
        value: "Music|Music History",
        display: "Histoire de la musique",
      },
      {
        value: "Music|Music Interviews",
        display: "Musique : les interviews",
      },
    ],
  },
  {
    value: "News",
    display: "Actualités",
    subCategories: [
      {
        value: "News|Business News",
        display: "Actualité économique",
      },
      {
        value: "News|Daily News",
        display: "Actus du jour",
      },
      {
        value: "News|Entertainment News",
        display: "Actualité du divertissement",
      },
      {
        value: "News|News Commentary",
        display: "Actualité : analyses",
      },
      {
        value: "News|Politics",
        display: "Politique",
      },
      {
        value: "News|Sports News",
        display: "Actualités sportives",
      },
      {
        value: "News|Tech News",
        display: "Actualités technologiques",
      },
    ],
  },
  {
    value: "Religion & Spirituality",
    display: "Religion et spiritualité",
    subCategories: [
      {
        value: "Religion & Spirituality|Buddhism",
        display: "Bouddhisme",
      },
      {
        value: "Religion & Spirituality|Christianity",
        display: "Christianisme",
      },
      {
        value: "Religion & Spirituality|Hinduism",
        display: "Hindouisme",
      },
      {
        value: "Religion & Spirituality|Islam",
        display: "Islam",
      },
      {
        value: "Religion & Spirituality|Judaism",
        display: "Judaïsme",
      },
      {
        value: "Religion & Spirituality|Religion",
        display: "Religion",
      },
      {
        value: "Religion & Spirituality|Spirituality",
        display: "Spiritualité",
      },
    ],
  },
  {
    value: "Science",
    display: "Sciences",
    subCategories: [
      {
        value: "Science|Astronomy",
        display: "Astronomie",
      },
      {
        value: "Science|Chemistry",
        display: "Chimie",
      },
      {
        value: "Science|Earth Sciences",
        display: "Sciences de la Terre",
      },
      {
        value: "Science|Life Sciences",
        display: "Sciences de la vie",
      },
      {
        value: "Science|Mathematics",
        display: "Mathématiques",
      },
      {
        value: "Science|Natural Sciences",
        display: "Sciences naturelles",
      },
      {
        value: "Science|Nature",
        display: "Nature",
      },
      {
        value: "Science|Physics",
        display: "Physique",
      },
      {
        value: "Science|Social Sciences",
        display: "Sciences sociales",
      },
    ],
  },
  {
    value: "Society & Culture",
    display: "Culture et société",
    subCategories: [
      {
        value: "Society & Culture|Documentary",
        display: "Documentaire",
      },
      {
        value: "Society & Culture|Personal Journals",
        display: "Journaux personnels",
      },
      {
        value: "Society & Culture|Philosophy",
        display: "Philosophie",
      },
      {
        value: "Society & Culture|Places & Travel",
        display: "Destinations et voyages",
      },
      {
        value: "Society & Culture|Relationships",
        display: "Relations",
      },
    ],
  },
  {
    value: "Sports",
    display: "Sports",
    subCategories: [
      {
        value: "Sports|Baseball",
        display: "Baseball",
      },
      {
        value: "Sports|Basketball",
        display: "Basketball",
      },
      {
        value: "Sports|Cricket",
        display: "Cricket",
      },
      {
        value: "Sports|Fantasy Sports",
        display: "Sports virtuels",
      },
      {
        value: "Sports|Football",
        display: "Football américain",
      },
      {
        value: "Sports|Golf",
        display: "Golf",
      },
      {
        value: "Sports|Hockey",
        display: "Hockey sur glace",
      },
      {
        value: "Sports|Rugby",
        display: "Rugby",
      },
      {
        value: "Sports|Running",
        display: "Course à pied",
      },
      {
        value: "Sports|Soccer",
        display: "Football",
      },
      {
        value: "Sports|Swimming",
        display: "Natation",
      },
      {
        value: "Sports|Tennis",
        display: "Tennis",
      },
      {
        value: "Sports|Volleyball",
        display: "Volley-ball",
      },
      {
        value: "Sports|Wilderness",
        display: "Nature",
      },
      {
        value: "Sports|Wrestling",
        display: "Catch",
      },
    ],
  },
  {
    value: "Technology",
    display: "Technologies",
  },
  {
    value: "True Crime",
    display: "Criminologie",
  },
  {
    value: "TV & Film",
    display: "Télévision et cinéma",
    subCategories: [
      {
        value: "TV & Film|After Shows",
        display: "Dans les coulisses",
      },
      {
        value: "TV & Film|Film History",
        display: "Histoire du cinéma",
      },
      {
        value: "TV & Film|Film Interviews",
        display: "Cinéma : les interviews",
      },
      {
        value: "TV & Film|Film Reviews",
        display: "Cinéma : les critiques",
      },
      {
        value: "TV & Film|TV Reviews",
        display: "Séries : les critiques",
      },
    ],
  },
];
